defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/Header/Header', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
], function (m) {
  const Header = {
    controller: function ({ controller }) {
      Object.assign(this, controller);
    },
    view: function (ignoreController, { heading, subheading }) {
      if (!heading?.length && !subheading?.length) {
        return <div />;
      }
      return (
        <div className='js-multi-client__flow-step-header relative px-30 pt-10 pb-10 text-center md:pt-46'>
          {heading?.length ? <h1 className='text-black text-28 font-bold mt-0 mb-14 md:text-40'>{heading}</h1> : null}
          {subheading?.length ? <h2 className='m-0 text-gray-400 text-16 leading-24 font-semibold'>{m.trust(subheading)}</h2> : null}
        </div>
      );
    }
  };
  return Header;
});