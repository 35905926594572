defineDs('DanskeSpil/Domain/Lotto/Scripts/Models/LottoGame',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Core/Model',
    'DanskeSpil/Domain/Lotto/Scripts/Helpers/SaturdayLottoApi',
    'DanskeSpil/Domain/Lotto/Scripts/Helpers/WednesdayLottoApi',
    'DanskeSpil/Domain/Lotto/Scripts/Helpers/LottoInfo',
    'DanskeSpil/Domain/Lotto/Scripts/Helpers/LottoUtils',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/DataLayer'
  ],
  function (m, Model, SaturdayLottoApi, WednesdayLottoApi, LottoInfo, LottoUtils, DataLayer) {

    // Model:
    var LottoGame = Model('LottoGame', function (content) {

      // Dispatch functions for feed and api:
      this.infoFeed = function () {
        return LottoInfo.saturday();
      }.bind(this);

      this.api = function () {
        return SaturdayLottoApi;
      }.bind(this);

      // Variables:
      this._generatedRows = m.prop(content._generatedRows || []); // Use function generatedRows for reaching this
      this._generatedJokerRowsSaturday = m.prop(content._generatedJokerRowsSaturday || []);
      this._generatedJokerRowsWednesday = m.prop(content._generatedJokerRowsWednesday || []);
      this.couponId = m.prop(content.couponId || null);
      this.extraCouponId = m.prop(content.extraCouponId || null);
      this.gameType = m.prop('saturday'); // For legacy wednesday/saturday stuff
      this.numberOfDraws = m.prop(typeof content.numberOfDraws !== 'undefined' ? content.numberOfDraws : this.infoFeed().getDrawRepeatDefault()); // NEED TO USE TYPEOF UNDEFINED CHECK HERE, BECAUSE 0 IS A VALID OPTION
      this.firstDrawNo = m.prop(content.firstDrawNo);
      this.firstWagerPrice = m.prop(content.firstWagerPrice || null);
      this.upsellExtra = m.prop(content.upsellExtra);
      this.getDrawRepeatMax = m.prop(this.infoFeed().getDrawRepeatMax());
      this.numbersIntervalMax = m.prop(36);
      this.playType = m.prop(content.playType || null);
      this.purchaseTracked = m.prop(content.purchaseTracked || false);
      this.ready = m.prop(m.deferred());
      this.rebuyCouponId = m.prop(content.rebuyCouponId || null);
      this.rows = m.prop(content.rows || []);
      this.rowsToGenerate = m.prop(content.rowsToGenerate || 0); // For playtypes Lightning and Lucky
      this.startUrl = m.prop(content.startUrl || window.location.href.split('?')[0]);
      this.status = m.prop(content.status || 'open');
      this.systemName = m.prop(content.systemName || (this.playType() === 'System' ? 'M 8-8' : null)); // Required by LottoSystem
      // we want to check the typeof `withJoker...` as it would be set to `false` when the user selects `no joker`
      // if the game instance is new or the user has not interacted with the joker selector than we want to set it to `null`
      this.withJokerSaturday = m.prop(typeof content.withJokerSaturday === 'boolean' ? content.withJokerSaturday : null);
      this.withJokerWednesday = m.prop(typeof content.withJokerWednesday === 'boolean' ? content.withJokerWednesday : null);
      this.isProductDetailsTracked = m.prop(content.isProductDetailsTracked || false);
      this.isAddToCartTracked = m.prop(content.isAddToCartTracked || false);
      this.isPurchaseCompleteTracked = m.prop(content.isPurchaseCompleteTracked || false);
      this.plusSubscriptionJackpot = m.prop(content.plusSubscriptionJackpot || null);
      this.firstDepositInfo = m.prop(content.firstDepositInfo || null);
      this.playTogetherDepositType = m.prop(content.playTogetherDepositType || null);
      this.rowPriceChanged = m.prop(false);
      this.originalNumberOfDraws = m.prop(null);
      this.originalPrice = m.prop(null);
      this.originalExtraDraw = m.prop(null);
      this.reducedWeeks = m.prop(false);
      this.verticalType = m.prop(content.verticalType);
      this.clientContext = m.prop(content.clientContext || null);
      this.trackedInteractionCreationAction = m.prop(content.trackedInteractionCreationAction || null);

      // Constants:
      this.classicMaxRows = m.prop(50);
      this.numberOfDrawsOptions = m.prop(this.infoFeed().getDrawRepeatOptions());
      this.stakePerJoker = m.prop(this.infoFeed().getJokerPrice());

      // Functions:
      this.numbersPerRowMin = function () {
        return this.numbersPerRow('min');
      }.bind(this);

      this.numbersPerRowMax = function () {
        return this.numbersPerRow('max');
      }.bind(this);

      this.numbersPerRow = function (type) {
        var playType = this.playType();

        // Classic:
        if (playType === 'Classic') {
          return 7;

          // Lucky:
        } else if (playType === 'Lucky') {
          return type === 'min' ? 1 : 6;
        }

        // System:
        var minimum = 0;
        var playSystems = this.infoFeed().getPlaySystems();
        var systemName = this.systemName() || playSystems[0].name;

        for (var i = 0; i < playSystems.length; i++) {
          var playSystem = playSystems[i];

          if (playSystem.name === systemName) {
            minimum = parseInt(playSystem.selectedNumbersCount, 10);

            break;
          }
        }

        return minimum;
      }.bind(this);

      // Functions - For manipulating rows:
      this.rowCount = function () {
        var count = 0;
        var rows = this.rows();
        var amount = rows.length;

        for (var i = 0; i < amount; i++) {
          var row = rows[i];

          if (row.count > count) {
            count = row.count + 1;
          } else {
            count++;
          }
        }

        return count;
      }.bind(this);

      this.sortRow = function (numbers) {
        numbers.sort(function (a, b) {
          return a.number < b.number ? -1 : 1;
        });

        return numbers;
      };

      this.addNumber = function (row, number, autogenerated) {
        row = this.getRow(row);

        if (row.numbers.length >= this.numbersPerRowMax()) {
          return;
        }

        row.numbers.push({ number: number, autogenerated: autogenerated || false });
        row.numbers = this.sortRow(row.numbers);

        this.save();
      }.bind(this);

      this.removeNumber = function (row, number) {
        row = this.getRow(row);

        var index = -1;
        var numbers = row.numbers;

        for (var i = 0; i < numbers.length; i++) {
          if (numbers[i].number === number) {
            index = i;

            break;
          }
        }

        if (index > -1) {
          row.numbers.splice(index, 1);
        }

        row.numbers = this.sortRow(row.numbers);

        this.save();
      }.bind(this);

      this.addRow = function (numbers) {
        numbers = numbers || [];

        var count = this.rowCount();

        this.rows().push({ count: count, numbers: numbers, state: 'clean' });

        this.save();
      }.bind(this);

      this.removeRow = function (row) {
        var rows = this.rows();

        rows.splice(row - 1, 1);

        this.rows(rows);

        if (rows.length === 0) {
          this.addRow();
        }

        this.save();
      }.bind(this);

      this.removeAllRows = function () {
        this.rows([]);

        this.addRow();
        this.addRow();

        this.save();
      }.bind(this);

      this.resetRow = function (rowNumber) {
        var row = this.getRow(rowNumber);

        row.numbers = [];
        row.state = 'clean';

        this.setRow(rowNumber, row);

        this.save();
      }.bind(this);

      this.setRow = function (row, content) {
        var rows = this.rows();
        rows[row - 1] = content;
        this.rows(rows);
        this.save();
      }.bind(this);

      // Classic and System: generate numbers for a specific row:
      this.autogenerateNumbers = function (rowNumber) {
        var playType = this.playType();

        if (['Classic', 'System'].indexOf(playType) === -1) {
          console.error('LottoGame autogenerateNumbers: This method cannot be used for playType', playType);

          return;
        }

        var self = this;
        var deferred = m.deferred();
        var row = this.getRow(rowNumber);
        var numbers = [];

        // Prepare list of existing user selected numbers:
        for (var i = 0; i < row.numbers.length; i++) {
          var number = row.numbers[i];

          if (!number.autogenerated) {
            numbers.push(number.number);
          }
        }

        // Setup options for random service:
        var options = { playType: playType, rowsToGenerate: 1, requiredNumbers: numbers };

        if (playType === 'System') {
          options.system = this.systemName();
        }

        // Call service:
        this.api().getRandomNumbers(options).then(function (rows) {
          rows = rows[0];

          const manualSelectedNumbers = row.numbers.filter((number) => !number.autogenerated);
          row.numbers = manualSelectedNumbers;
          self.setRow(rowNumber, row);

          for (var i = 0; i < rows.length; i++) {
            var generatedNumber = rows[i];

            if (generatedNumber && numbers.indexOf(generatedNumber) === -1) {
              self.addNumber(rowNumber, generatedNumber, true);
            }
          }

          deferred.resolve();
        }, function () {
          deferred.reject();
        });

        return deferred.promise;
      }.bind(this);

      this.generateSystemRows = function () {
        var self = this;
        var deferred = m.deferred();

        this.api().getSystemKeys(this.systemName()).then(function (systemKeys) {
          if (systemKeys) {
            var generatedRows = [];

            if (self.isGameValid()) {
              var selectedNumbers = self.getRow(1).numbers;

              // Merge users selected numbers into the system key
              generatedRows = systemKeys.map(function (row, i) {
                var numbers = row.map(function (index) {
                  // replace each index with the numbers chosen by the user
                  return { number: selectedNumbers[index - 1].number, autogenerated: true };
                });

                return { count: i, numbers: numbers, state: 'clean' };
              });

            } else {

              // Just use the pure system key
              generatedRows = systemKeys.map(function (row, i) {
                var numbers = row.map(function (index) {
                  return { number: index, autogenerated: true };
                });
                return { count: i, numbers: numbers, state: 'clean' };
              });

            }

            deferred.resolve(generatedRows);
          }
        }, function () {
          deferred.reject();
        });

        return deferred.promise;
      }.bind(this);

      this.areAllRowsValid = () => {
        return this.rows().every((row) => this.isRowValid(row.count + 1));
      };

      this.autofillClassicRows = async () => {
        const promises = [];
        const emptyRows = [];
        for (let i = 0; i < this.rows().length; i++) {
          const row = this.rows()[i];
          const rowNumber = row.count + 1;
          if (!this.isRowValid(rowNumber)) {
            if (row.numbers.length > 0) {
              promises.push(this.autogenerateNumbers(rowNumber));
            } else {
              emptyRows.push(row);
            }
          }
        }
        const fullyGeneratedRows = this.api().getRandomNumbers({ rowsToGenerate: emptyRows.length }).then((rowsNumbers) => {
          for (let i = 0; i < rowsNumbers.length; i++) {
            const rowNumbers = rowsNumbers[i];
            const emptyRow = emptyRows[i];
            for (let j = 0; j < rowNumbers.length; j++) {
              this.addNumber(emptyRow.count + 1, rowNumbers[j], true);
            }
          }
        });
        promises.push(fullyGeneratedRows);
        await Promise.all(promises);

        this.save();
      };

      // Lightning, Lucky and System: generate output rows:
      this.gameGenerateRows = function () {
        var self = this;
        var deferred = m.deferred();

        if (['Lightning', 'Lucky', 'System'].indexOf(this.playType()) === -1) {
          console.error('LottoGame gameGenerateRows: This method cannot be used for playType', this.playType());

          deferred.reject();

          return;
        }

        if (this.playType() === 'System') {

          // System:
          this.generateSystemRows().then(function (generatedRows) {
            self.generatedRows(generatedRows);

            self.save();

            deferred.resolve();
          }, function () {
            deferred.reject();
          });

        } else {

          // Lightning and Lucky:
          var row = this.getRow(1);
          var requiredNumbers = [];

          if (row && row.numbers.length > 0) {
            requiredNumbers = row.numbers.map(function (number) {
              return number.number;
            });
          }

          var options = { rowsToGenerate: this.rowsToGenerate() };

          if (this.playType() === 'Lucky') {
            options.requiredNumbers = requiredNumbers.join(',');
          }

          this.api().getRandomNumbers(options).then(function (rows) {
            var generatedRows = [];

            for (var i = 0; i < rows.length; i++) {
              var row = rows[i];
              var numbers = row.map(function (number) { return { number: number, autogenerated: true }; });

              generatedRows.push({ count: i, numbers: numbers, state: 'clean' });
            }

            self.generatedRows(generatedRows);
            self.save();

            deferred.resolve();
          }, function () {
            deferred.reject();
          }).bind(this);
        }

        return deferred.promise;
      }.bind(this);

      this.gameGenerateJokerRows = function (type) {
        var api = type === 'Wednesday' ? WednesdayLottoApi : SaturdayLottoApi;
        var deferred = m.deferred();
        var self = this;

        api.getRandomJokerNumbers().then(function (rows) {
          var generatedJokerRows = [];

          for (var i = 0; i < rows.length; i++) {
            var row = rows[i];
            var numbers = row.map(function (number) { return { number: number, autogenerated: true }; });

            generatedJokerRows.push({ count: i, numbers: numbers, state: 'clean' });
          }

          self.generatedJokerRows(type, generatedJokerRows);
          self.save();

          deferred.resolve();
        }, function () {
          deferred.reject();
        });

        return deferred.promise;
      }.bind(this);

      // Functions for number of draws:
      this.setNumberOfDraws = function (draws) {
        this.numberOfDraws(draws);
        this.save();
      }.bind(this);

      this.setFirstDrawNo = function (firstDrawNo) {
        this.firstDrawNo(firstDrawNo);
        this.save();
      }.bind(this);

      this.setUpsellExtra = (state) => {
        this.upsellExtra(state);
        this.save();
      };

      // Functions for purchase:
      this.purchase = function (params) {
        params = params || {};

        var deferred = m.deferred();
        var proceed = true;
        var self = this;

        if (this.status() != 'open') {
          deferred.reject();
        } else if (!params.drawId) {
          console.error('No drawId given.');

          deferred.reject({ errorMessage: 'NUMBERGAMES.NO_OPEN_DRAW' });
        } else {
          this.status('pending-confirmation');

          this.save();

          var rows = this.playType() === 'System' ? this.getRowsSummary(false) : this.getRowsSummary();
          var rowNumbers = rows.map(function (row) { return { numbers: row }; });

          var options = {
            upsellExtra: this.upsellExtra() || false,
            drawId: params.drawId,
            firstDrawNo: this.firstDrawNo() || null,
            totalPrice: parseInt(self.totalPrice(), 10),
            multidraw: parseInt(self.numberOfDraws(), 10),
            playType: this.playType() || null,
            rows: rowNumbers,
            salesChannel: (LottoUtils.isMobile() || LottoUtils.isTabletDevice()) ? 'mobile' : 'web'
          };

          // Prize notification - register only enabling:
          if (params.notifyPrizeEmail) {
            options.notifyPrizeEmail = true;
          }

          if (params.notifyPrizeSms) {
            options.notifyPrizeSms = true;
          }

          // Jokers:
          if (this.withJokerSaturday()) {
            options.jokerSaturdayRows = this.getJokerRowsSummary('Saturday');

            if (options.jokerSaturdayRows.length === 0) {
              proceed = false;

              deferred.reject('joker-saturday-error');
            }
          }

          if (this.withJokerWednesday()) {
            options.jokerWednesdayRows = this.getJokerRowsSummary('Wednesday');

            if (options.jokerWednesdayRows.length === 0) {
              proceed = false;

              deferred.reject('joker-wednesday-error');
            }
          }

          // Proceed:
          if (proceed) {

            // System:
            if (this.playType() === 'System') {
              options.system = this.systemName();
            }

            // Create request:
            var request = this.api().createCoupon(options);

            request.then(function (data) {
              self.status('completed');

              self.couponId(data.couponId);
              if (data.extraCouponId) {
                self.extraCouponId(data.extraCouponId);
              }

              self.save();

              deferred.resolve(data);
            }, function (data) {
              self.status('open');
              self.save();

              deferred.reject(data);
            });

          }
        }

        return deferred.promise;
      }.bind(this);

      this.prepareConfirm = function () {
        var confirmUrl = this.confirmUrl();
        var deferred = m.deferred();
        var playType = this.playType();
        var status = this.status();

        if (status !== 'processing') {
          var promises = playType !== 'Classic' ? [this.gameGenerateRows()] : [];
          var self = this;

          if (status === 'prepare-confirm-error') {
            status = 'open';
          }

          this.status(this.numberOfDraws() > 0 ? 'processing' : status);

          if (this.withJokerSaturday()) {
            promises.push(this.gameGenerateJokerRows('Saturday'));
          }

          if (this.withJokerWednesday()) {
            promises.push(this.gameGenerateJokerRows('Wednesday'));
          }

          m.sync(promises).then(function () {
            self.status(status);
            self.save();

            deferred.resolve(confirmUrl);
          }, function () {
            self.status('prepare-confirm-error');

            self.save();

            deferred.reject();
          });
        }

        return deferred.promise;
      }.bind(this);

      // Functions - For tracking (DataLayer eCommerce buzzword)

      this.createDataLayerJoker = function (day, subscription) {
        var joker;

        if (!subscription) {
          joker = {
            name: 'joker',
            price: DataLayer.rowPrice(this.stakePerJoker(), this.numberOfDraws(), 'joker'),
            brand: 'dlo_jok',
            category: DataLayer.categoryName(this.playType()),
            variant: DataLayer.variantName('joker', this.numberOfDraws(), day),
            quantity: 1
          };
        }

        if (subscription) {
          joker = {
            name: 'plus',
            price: DataLayer.rowPrice(this.stakePerJoker(), this.numberOfDraws(), 'plus_joker'),
            brand: 'dlo_plu',
            category: DataLayer.categoryName(this.playType()),
            variant: DataLayer.variantName('plus_joker', this.numberOfDraws(), day),
            quantity: 1
          };
        }

        if (this.trackedInteractionCreationAction()) {
          joker.coupon = this.trackedInteractionCreationAction();
        }

        return joker;
      };

      this.createDataLayerProduct = function (type, event) {
        // Product array for datalayer
        var dataLayerProducts = [];
        var product = {};
        var subscription = type === 'plus';
        const hasExtraDraws = this.gameExtraDraws().length > 0;
        const hasUpsellExtra = this.upsellExtra();
        const isAdvanceBuy = this.firstDrawNo();

        // Main product
        if (type === 'plus') {
          // subscription product (PLUS abodoment)
          product.name = 'plus';
          product.price = DataLayer.rowPrice(this.infoFeed().data(), this.numberOfDraws(), 'plus');
          product.brand = 'dlo_plu';
          product.category = DataLayer.categoryName(this.playType());
          product.variant = DataLayer.variantName('plus_lotto', this.numberOfDraws(), this.playType(), this.systemName());
          product.quantity = this.getRowAmount();
        } else {
          product.name = 'lotto';
          product.price = DataLayer.rowPrice(this.infoFeed().data(), this.numberOfDraws());
          product.brand = 'dlo_lot';
          product.category = DataLayer.categoryName(this.playType());
          product.variant = DataLayer.variantName('lotto', this.numberOfDraws(), this.playType(), this.systemName());

          if (type === 'playtogether') {
            product.category = 'spil_sammen';
          }

          if (event != 'productDetails') {
            product.quantity = this.getRowAmount();
          }

          if (this.trackedInteractionCreationAction()) {
            product.coupon = this.trackedInteractionCreationAction();
          }
        }

        if (type !== 'plus' && hasExtraDraws && !hasUpsellExtra) {
          product.price = DataLayer.rowPrice(this.infoFeed().data(), (this.numberOfDraws() - this.gameExtraDraws().length));
        }

        if (!isAdvanceBuy) {
          dataLayerProducts.push(product);
        }

        if (type !== 'plus') {
          if (hasExtraDraws || hasUpsellExtra || isAdvanceBuy) {
            const productCopy = { ...product };
            productCopy.name = 'julelotto';
            if (hasExtraDraws || hasUpsellExtra) {
              productCopy.price = DataLayer.rowPrice(this.infoFeed().data(), this.gameExtraDraws().length);
            }
            if (hasExtraDraws) {
              productCopy.coupon = 'purchase_with_extra_draw';
            }
            if (hasUpsellExtra) {
              productCopy.coupon = 'purchase_with_upsell';
            }
            if (isAdvanceBuy) {
              productCopy.coupon = 'purchase_advance_draw';
            }

            dataLayerProducts.push(productCopy);
          }
        }

        // Check for joker saturday
        if (this.withJokerSaturday()) {
          !subscription ? dataLayerProducts.push(this.createDataLayerJoker('saturday')) : dataLayerProducts.push(this.createDataLayerJoker('saturday', 'plus'));
        }

        // Check for joker wednesday
        if (this.withJokerWednesday()) {
          !subscription ? dataLayerProducts.push(this.createDataLayerJoker('wednesday')) : dataLayerProducts.push(this.createDataLayerJoker('wednesday', 'plus'));
        }

        return dataLayerProducts;
      };

      this.trackingAddToCart = function (type) {
        if (this.isAddToCartTracked() == false) {
          this.isAddToCartTracked(true);
          this.save();

          // Push DataLayer addToCart Event
          if (type == 'plus' || type === 'playtogether') {
            DataLayer.addToCart(this.createDataLayerProduct(type));
          } else {
            DataLayer.addToCart(this.createDataLayerProduct());
          }
        } else {
          console.warn('addToCart has been pushed already!');
        }
      }.bind(this);

      this.trackingProductDetails = function () {
        // Check if the event has already been pushed
        if (this.isProductDetailsTracked() == false) {
          this.isProductDetailsTracked(true);
          this.save();

          // Push DataLayer addToCart Event
          DataLayer.productDetail(this.createDataLayerProduct(false, 'productDetails'));
        } else {
          console.warn('productDetails has been pushed already!');
        }
      }.bind(this);

      this.trackingPurchaseComplete = function (type) {
        if (this.isPurchaseCompleteTracked() == false) {
          this.isPurchaseCompleteTracked(true);
          this.save();

          // Push DataLayer purchaseComplete Event
          DataLayer.purchaseCompleted({
            totalPrice: this.totalPrice().toString(),
            id: this.couponId() || this.fallbackFakeCouponId()
          }, this.createDataLayerProduct(type));
        } else {
          console.warn('purchaseCompleted has been pushed already!');
        }
      }.bind(this);

      // Ensighten will group all events together if the ID is not unique.
      // In Playtogether we don't have a couponId upon purchase, so we create a fake one
      this.fallbackFakeCouponId = function () {
        var couponId = 'NA-LOT-' + Math.random().toString(36).slice(2);

        return couponId;
      };

      // Functions - For extracting data:
      this.getRow = function (row) {
        // Note: the row index starts with 1:

        return this.rows()[row - 1];
      }.bind(this);

      this.getRows = function (valid) {
        var maximum = this.numbersIntervalMax();
        var minimum = 0;

        if (valid === true) {
          maximum = this.numbersPerRowMax();
          minimum = this.numbersPerRowMin();
        }

        return this.rows().filter(function (row) {
          return (row.state !== 'remove' && row.numbers.length >= minimum && row.numbers.length <= maximum);
        });
      }.bind(this);

      this.hasNumber = function (row, number) {
        row = this.getRow(row);

        var numbers = row.numbers;

        for (var i = 0; i < numbers.length; i++) {
          if (numbers[i].number === number) {
            return true;
          }
        }

        return false;
      }.bind(this);

      this.drawDateHtml = function (delimiter) {
        var openDraw = this.infoFeed().data().openDraw;
        if (!openDraw || !openDraw.closingTime) {
          return '';
        }

        delimiter = delimiter || ' - ';

        var closingTime = openDraw.closingTime;
        var draws = this.numberOfDraws();

        var date = new Date(closingTime);

        var result = LottoUtils.formatISO8601(date.toISOString(), { includeTime: false });

        if (draws > 1) {
          date.setDate(date.getDate() + ((draws - 1) * 7));
          result = '<span class="multiple-draws">' + result + delimiter + LottoUtils.formatISO8601(date.toISOString(), { includeTime: false }) + '</span>';
        }

        return result;
      }.bind(this);

      this.drawDateHtmlShort = function () {
        var dates = this.drawDates();
        var firstDate = dates[0];
        var lastDate = dates[dates.length - 1];

        // if there's more than one date, output the range. otherwise output the first date.
        if (dates.length > 1) {
          return firstDate.draw.getDate() + '/' + (firstDate.draw.getMonth() + 1) + ' - ' + lastDate.draw.getDate() + '/' + (lastDate.draw.getMonth() + 1) + ' ' + lastDate.draw.getFullYear();
        } else {
          return firstDate.draw.getDate() + '/' + (firstDate.draw.getMonth() + 1) + ' ' + firstDate.draw.getFullYear();
        }
      }.bind(this);

      this.drawDates = () => {
        var drawDates = [];
        var draws = this.numberOfDraws();
        var drawsFromLottoInfo = this.infoFeed().data().draws;
        var firstDraw = null;
        if (this.firstDrawNo()) {
          firstDraw = LottoInfo.data().lottoSaturday.draws.filter(function (draw) {
            return draw.id === this.firstDrawNo();
          }.bind(this))[0];
        }
        var advanceBuy = !!firstDraw;
        firstDraw = firstDraw || LottoInfo.data().lottoSaturday.openDraw;


        if (!firstDraw || !firstDraw.closingTime) {
          return drawDates;
        }

        if (drawsFromLottoInfo.length > 0) {
          var firstDrawIndex = -1;
          for (var i = 0; i < drawsFromLottoInfo.length; i++) {
            if (drawsFromLottoInfo[i].id === firstDraw.id) {
              firstDrawIndex = i;
              break;
            }
          }
          var feedDrawDates = drawsFromLottoInfo.slice(firstDrawIndex, firstDrawIndex + draws);
          drawDates = feedDrawDates.map(function (draw) {
            let hasJoker = this.withJokerSaturday();
            if (draw.drawType?.toLowerCase() !== 'regular') {
              hasJoker = false;
            }
            return {
              draw: new Date(draw.closingTime),
              hasJoker: hasJoker
            };
          }.bind(this));
        } else {
          // TODO: check if this way of getting the drawDates is relevant anymore as we can get them directly from the feed
          var closingTime = firstDraw.closingTime;
          while (draws--) {
            var futureDate = new Date(closingTime);
            futureDate.setDate(futureDate.getDate() + (draws * 7));
            drawDates.push({
              draw: futureDate,
              hasJoker: this.withJokerSaturday()
            });
          }
        }

        // if joker on wednesdays are added, add their dates.
        if (this.withJokerWednesday()
            && LottoInfo.data()
            && LottoInfo.data().jokerWednesday) {

          if (LottoInfo.data().jokerWednesday.draws && LottoInfo.data().jokerWednesday.draws.length) {
            draws = this.numberOfDraws();
            LottoInfo.data().jokerWednesday.draws.forEach((draw) => {
              if (!!draws && (!advanceBuy || draw.closingTime >= firstDraw.closingTime)) {
                let hasJoker = this.withJokerWednesday();
                if (draw.drawType?.toLowerCase() !== 'regular') {
                  hasJoker = false;
                }
                drawDates.push({
                  draw: new Date(draw.closingTime),
                  hasJoker: hasJoker
                });
                draws--;
              }
            });
          } else if (LottoInfo.data().jokerWednesday.openDraw && LottoInfo.data().jokerWednesday.openDraw) {
            // TODO: check if this way of getting the drawDates is relevant anymore as we can get them directly from the feed
            var firstWednesdayJoker = new Date(LottoInfo.data().jokerWednesday.openDraw.closingTime);

            draws = this.numberOfDraws();
            while (draws--) {
              drawDates.push({
                draw: new Date(firstWednesdayJoker),
                hasJoker: true
              });
              firstWednesdayJoker.setDate(firstWednesdayJoker.getDate() + 7);
            }
          }
        }

        if (this.upsellExtra() && this.extraDraw()?.closingTime) {
          const extraDrawDate = new Date(this.extraDraw().closingTime);
          drawDates.push({
            draw: extraDrawDate,
            hasJoker: false
          });
        }

        drawDates.sort(function (a, b) {
          return a.draw.getTime() - b.draw.getTime();
        });

        return drawDates;
      };

      this.rawDrawsDataFromInfoFeed = () => {
        const drawTimes = this.drawDates().map((draw) => {
          return new Date(draw.draw).getTime();
        });

        const feedDraws = this.infoFeed().data().draws.filter((feedDraw) => {
          const feedDrawTime = new Date(feedDraw.closingTime).getTime();
          return drawTimes.includes(feedDrawTime);
        });

        return feedDraws;
      };

      this.drawDatesWithoutJokerInfo = (numberOfDraws) => {
        var drawDates = [];
        var openDraw = this.infoFeed().data().openDraw;
        var draws = numberOfDraws || this.numberOfDraws();
        var drawsFromLottoInfo = this.infoFeed().data().draws;
        var firstDraw = this.infoFeed().data().openDraw;

        if (!openDraw || !openDraw.closingTime) {
          return drawDates;
        }

        var firstDrawIndex = -1;

        for (var i = 0; i < drawsFromLottoInfo.length; i++) {
          if (drawsFromLottoInfo[i].id === firstDraw.id) {
            firstDrawIndex = i;
            break;
          }
        }
        var feedDrawDates = drawsFromLottoInfo.slice(firstDrawIndex, firstDrawIndex + draws);
        drawDates = feedDrawDates.map(function (draw) {
          var drawObject = {
            draw: new Date(draw.closingTime)
          };

          if (draw.campaigns) {
            drawObject.campaigns = draw.campaigns;
            drawObject.campaignNumbers = draw.campaignNumbers;
            drawObject.campaignData = draw.campaignData;
          }
          if (draw.drawType) drawObject.drawType = draw.drawType;

          return drawObject;
        }.bind(this));

        if (this.upsellExtra() && !numberOfDraws && this.extraDraw()?.closingTime) {
          const extraDraw = this.extraDraw();
          drawDates.push({
            draw: new Date(extraDraw.closingTime),
            campaigns: extraDraw.campaigns,
            campaignNumbers: extraDraw.campaignNumbers,
            campaignData: extraDraw.campaignData,
            drawType: extraDraw.drawType
          });
        }

        drawDates.sort(function (a, b) {
          return a.draw.getTime() - b.draw.getTime();
        });

        return drawDates;
      };

      this.jokerDrawDates = function (drawDay) {
        if (!(LottoInfo.data()
            &&  LottoInfo.data().jokerSaturday
            &&  LottoInfo.data().jokerWednesday
            &&  LottoInfo.data().jokerSaturday.draws
            &&  LottoInfo.data().jokerWednesday.draws)) return;

        if (this.drawDatesWithoutJokerInfo().length === 0) return [];

        var saturdayDraws =  LottoInfo.data().jokerSaturday.draws.map(function (draw) {
          draw.dayName = 'saturday';
          return draw;
        });
        var wednesdayDraws =  LottoInfo.data().jokerWednesday.draws.map(function (draw) {
          draw.dayName = 'wednesday';
          return draw;
        });
        var combinedDraws = saturdayDraws.concat(wednesdayDraws);
        combinedDraws.sort(function (a, b) {
          return (a.closingTime).localeCompare((b.closingTime));
        });

        switch (drawDay) {
        case 'saturday':
          return saturdayDraws.slice(0, this.numberOfDraws());
        case 'wednesday':
          return wednesdayDraws.slice(0, this.numberOfDraws());
        case 'both':
          return combinedDraws.slice(0, (this.numberOfDraws() * 2));
        }
      }.bind(this);

      this.campaignDrawsCount = function (wantedCampaign) {
        return this.rawDrawsDataFromInfoFeed().reduce(function (count, draw) {
          if (Array.isArray(wantedCampaign)) {
            return draw?.campaignNumbers?.some((campaignNumber) => {
              return wantedCampaign.includes(campaignNumber);
            }) ? ++count : count;
          }
          if (typeof wantedCampaign === 'number') {
            return draw?.campaignNumbers?.includes(wantedCampaign) ? ++count : count;
          }
          return draw?.campaigns?.some(function (c) {
            return c.indexOf(wantedCampaign) === 0;
          }) ? ++count : count;
        }, 0);
      };

      this.generatedRows = function (value) {
        var playType = this.playType();

        // Classic and Lightning:
        if (playType === 'Classic') {
          if (value) {
            console.error('LottoGame: play type Classic does not support setting generatedRows.');
          }

          return this.getRows(true);
        }

        // Lightning, Lucky and System:
        if (playType === 'Lightning' || playType === 'Lucky' || playType === 'System') {
          if (typeof value !== 'undefined') {
            this._generatedRows(value);
          }

          return this._generatedRows();
        }
      }.bind(this);

      this.generatedJokerRows = function (type, value) {
        if (value) {
          this['_generatedJokerRows' + type](value);
        }

        return this['_generatedJokerRows' + type]();
      }.bind(this);

      this.checkGeneratedConsistency = function () {
        var playType = this.playType();

        // Classic and Lightning:
        if (playType === 'Classic' || playType === 'Lightning') {
          return true;
        }

        // Lucky:
        if (playType === 'Lucky') {
          return this.generatedRows().length == this.rowsToGenerate();
        }

        // System: TODO: check that amount of generated rows corresponds to the chosen system:
        if (playType === 'System') {
          return this.generatedRows().length == this.getSystemRowAmount();
        }
      }.bind(this);

      this.getRowsSummary = function (useGenerated) {
        useGenerated = typeof useGenerated === 'undefined' ? true : useGenerated;

        var summary = [];
        var rows = useGenerated ? this.generatedRows() : this.getRows(true);

        for (var i = 0; i < rows.length; i++) {
          var row = rows[i].numbers.map(function (numbers) { return numbers.number; });

          summary.push(row);
        }

        return summary;
      }.bind(this);

      this.getJokerRowsSummary = function (type) {
        var summary = [];
        var rows = this['_generatedJokerRows' + type]();

        for (var i = 0; i < rows.length; i++) {
          var row = rows[i].numbers.map(function (numbers) { return numbers.number; });

          summary.push({ numbers: row });
        }

        return summary;
      }.bind(this);

      this.getRowAmount = function () {
        var playType = this.playType();

        if (['Lucky', 'Lightning'].includes(playType)) {
          return this.rowsToGenerate();
        }

        if (playType === 'System') {
          return this.getSystemRowAmount();
        }

        // Classic:
        return this.getRows(true).length;
      }.bind(this);

      this.rowPrice = function (rowIndex) {
        var data = this.infoFeed().data();
        var rowPrice = rowIndex !== undefined && data.draws && data.draws[rowIndex] && data.draws[rowIndex].rowPrice ? data.draws[rowIndex].rowPrice : 0;

        return rowPrice;
      };

      this.extraDraw = () => {
        return this.infoFeed().data()?.draws?.find((draw) => draw.drawType?.toLowerCase() === 'extra');
      };
      this.gameExtraDraws = () => {
        return this.drawDatesWithoutJokerInfo().filter((draw) => draw.drawType?.toLowerCase() === 'extra');
      };

      this.extraDrawPrice = () => {
        return this.extraDraw()?.rowPrice || 0;
      };

      this.totalRowsPricePerDraw = function (drawIndex) {
        var rowAmount = this.getRowAmount();
        var price = 0;

        if (drawIndex !== undefined) {
          return this.rowPrice(drawIndex) * rowAmount;
        }

        for (var i = 0; i < this.numberOfDraws() ; i++) {
          price += rowAmount * this.rowPrice(i);
        }

        if (this.upsellExtra()) {
          price += rowAmount * this.extraDrawPrice();
        }

        return price;
      }.bind(this);

      this.totalPrice = function () { // Required by Purchase bar

        // Calculate total price:
        return this.totalJokerPrice() + this.totalRowsPricePerDraw();
      }.bind(this);

      this.jokerPrice = function () {
        return this.infoFeed().getJokerPrice() * 2;
      }.bind(this);

      this.totalJokerPrice = () => {
        let numberOfDraws = this.numberOfDraws();
        if (this.gameExtraDraws().length > 0 && !this.upsellExtra()) {
          numberOfDraws = numberOfDraws - this.gameExtraDraws().length;
        }
        return this.getJokerCount() * this.stakePerJoker() * (numberOfDraws || 1);
      };

      this.getJokerCount = function () {
        return (this.withJokerSaturday() ? 2 : 0) + (this.withJokerWednesday() ? 2 : 0);
      }.bind(this);

      this.isNumberOfDrawsExceed = function () {
        return this.numberOfDraws() > this.infoFeed().data().draws.length;
      }.bind(this);

      this.isGameValid = function () { // Required by PurchaseBar
        var playType = this.playType();

        // Not valid, if numberOfDraws is higher than known upcoming draws in the feed:
        if (this.isNumberOfDrawsExceed()) {
          return false;
        }

        // System:
        if (playType === 'System') {
          return (this.systemName() && this.getSystemNumberAmount() == this.getRow(1).numbers.length);
        }

        // Lucky, Classic:
        if (playType === 'Lucky' || playType === 'Classic') {
          return this.getRows(true).length > 0 && (this.totalPrice() > 0 || this.numberOfDraws() === 0);
        }

        // Lightning:
        return this.totalPrice() > 0 || this.numberOfDraws() === 0;
      }.bind(this);

      this.isRowValid = function (rowNumber) {
        return this.getRow(rowNumber).numbers.length === this.numbersPerRowMax();
      }.bind(this);

      this.isSubscriptionValid = function () {
        if (this.playType() === 'System') {
          // TODO - CR: System - ingen systemer, der overstiger 2.000 kr. (Kasper har listen, hvis det er)
          return true;
        }

        // Classic, Lykke, Lyn - mindst 10 rækker
        return this.getRowAmount() >= 10;
      }.bind(this);


      // System helpers:
      this.getSystemNumberAmount = function () {
        return this.systemName() ? this.systemName().split(' ')[1].split('-')[0] : 0;
      }.bind(this);

      this.getSystemRowAmount = function () {
        return this.systemName() ? this.systemName().split(' ')[1].split('-')[1] : 0;
      }.bind(this);

      this.trackPurchase = function () {
        var self = this;

        if (!self.purchaseTracked() && self.status() === 'completed') {
          window.DSAPI && DSAPI.ready(function () {
            self.purchaseTracked(true);
          });

          this.trackingPurchaseComplete();
        }
      }.bind(this);

      this.confirmUrl = function () {
        var feed = this.infoFeed();
        var data = feed ? feed.data() : null;

        if (data && data.confirmUrl) {
          return data.confirmUrl + '?gameInstanceId=' + this.id();
        }

        console.error('LottoGame: unable to return confirmUrl, feed is not loaded.');
      }.bind(this);

      this.receiptUrl = function () {
        var feed = this.infoFeed();
        var data = feed ? feed.data() : null;

        if (data && data.receiptUrl) {
          return data.receiptUrl + '?gameInstanceId=' + this.id();
        }

        console.error('LottoGame: unable to return receiptUrl, feed is not loaded.');
      }.bind(this);

      this.cancelUrl = function () {
        if (this.rebuyCouponId()) {
          // This is called when going from confirm page, back to completed games overview page.
          return this.startUrl();
        }

        if (this.clientContext() === 'multiClient') {
          var multiClientPath = window.location.href.split('#/');
          return this.startUrl() + '?gameInstanceId=' + this.id() + '#/' + multiClientPath;
        }

        // This is called when going from confirm page, back to game.
        return this.startUrl() + '?gameInstanceId=' + this.id();
      }.bind(this);

      this.copyFromOldCoupon = function () {
        var self = this;

        this.api().getCoupon(this.rebuyCouponId()).then(function (data) {
          const extraDraw = data.multiWagers?.find((wager) => wager.drawType?.toLowerCase() === 'extra');
          self.numberOfDraws(!data.multiWagers ? 1 : data.multiWagers.length);
          self.playType(data.playType);
          self.systemName(data.systemName);
          self.rowPriceChanged(data.rowPriceChanged);
          if (extraDraw) {
            self.originalExtraDraw(extraDraw);
          }
          self.reducedWeeks(data.rebuyDrawRepeatMax && data.rebuyDrawRepeatMax < self.numberOfDraws());
          if (!data.rebuyDrawRepeatMax && extraDraw) {
            self.reducedWeeks(true);
          }

          if (data.multiWagers?.length > 1) {
            self.firstWagerPrice(data.multiWagers[0].price);
          }

          if (self.rowPriceChanged()) {
            self.originalPrice((data.price * self.numberOfDraws()) + self.totalJokerPrice());
          }

          if (self.reducedWeeks()) {
            self.originalPrice((data.price * self.numberOfDraws()) + self.totalJokerPrice());
            self.originalNumberOfDraws(self.numberOfDraws());
            self.numberOfDraws(extraDraw ? self.numberOfDraws() - 1 : data.rebuyDrawRepeatMax);
          }

          var promises = [];

          // If systemName is defined, set playType to System, otherwise Classic:
          if (!self.playType() || self.playType().toLowerCase() === 'unknown') {
            self.playType(self.systemName() ? 'System' : 'Classic');
          }

          data.games.forEach(function (game) {
            var gameType = /wednesday/.test(game.gameType.toLowerCase()) ? 'wednesday' : 'saturday';
            var rows = [];
            var rowNumbers = [];

            // Lotto:
            if (game.gameType.substring(0, 5) === 'Lotto') {

              // Classic:
              if (self.playType() === 'Classic') {
                game.rows.forEach(function (row, index) {
                  rowNumbers = { count: index, numbers: [], state: 'clean' };

                  row.numbers.forEach(function (number) {
                    rowNumbers.numbers.push({ autogenerated: false, number: number.number });
                  });

                  self.rows().push(rowNumbers);
                });

                // System:
              } else if (self.playType() === 'System') {
                rowNumbers = { count: 0, numbers: [], state: 'clean' };

                game.systemNumbers.numbers.forEach(function (number) {
                  rowNumbers.numbers.push({ autogenerated: false, number: number.number });
                });

                self.rows().push(rowNumbers);

                promises.push(self.gameGenerateRows());

                // Lightning and Lucky:
              } else if (self.playType() === 'Lucky' || self.playType() === 'Lightning') {
                game.rows.forEach(function (row, index) {
                  rowNumbers = { count: index, numbers: [], state: 'clean' };

                  row.numbers.forEach(function (number) {
                    rowNumbers.numbers.push({ autogenerated: false, number: number.number });
                  });

                  rows.push(rowNumbers);
                });

                self.generatedRows(rows);
                self.rowsToGenerate(rows.length);
              }

              // Joker:
            } else {
              rows = [];

              if (gameType === 'wednesday') {
                self.withJokerWednesday(true);
              } else {
                self.withJokerSaturday(true);
              }

              game.rows.forEach(function (row, index) {
                var rowNumbers = { count: index, numbers: [], state: 'clean' };

                row.numbers.forEach(function (number) {
                  rowNumbers.numbers.push({ autogenerated: false, number: number.number });
                });

                rows.push(rowNumbers);
              });

              self.generatedJokerRows(gameType.charAt(0).toUpperCase() + gameType.slice(1), rows);
            }
          });

          m.sync(promises).then(function () {
            self.save();

            self.ready().resolve();
          });
        }, function () {

          self.ready().reject('coupon-not-found');
        });
      }.bind(this);

      this.sendToSubscription = function () {
        // Tracking for DataLayer
        this.trackingAddToCart('plus');

        const link = this.linkToPlusPurchase?.();

        if (link) {
          const linkArr = link.split('?');
          if (!(linkArr.includes('plus') || linkArr.includes('jackpot'))) linkArr.push('plus');
          linkArr.push('mcGameType=lotto');
          linkArr.push('mcGameId=' + this.id());
          location.href = linkArr.shift() + '?' + linkArr.join('&');
        } else {
          location.href = '/plus-abonnement/se-kurv?plus&mcGameType=lotto&mcGameId=' + this.id();
        }
      }.bind(this);

      this.toJSON = function () {
        return {
          _generatedRows: this._generatedRows(),
          _generatedJokerRowsSaturday: this._generatedJokerRowsSaturday(),
          _generatedJokerRowsWednesday: this._generatedJokerRowsWednesday(),
          gameType: this.gameType(),
          id: this.id(),
          numberOfDraws: this.numberOfDraws(),
          firstDrawNo: this.firstDrawNo(),
          firstWagerPrice: this.firstWagerPrice(),
          upsellExtra: this.upsellExtra(),
          playType: this.playType(),
          purchaseTracked: this.purchaseTracked(),
          rows: this.rows(),
          rowsToGenerate: this.rowsToGenerate(),
          startUrl: this.startUrl(),
          state: this.state(),
          status: this.status(),
          systemName: this.systemName(),
          timestamp: this.timestamp(),
          withJokerSaturday: this.withJokerSaturday(),
          withJokerWednesday: this.withJokerWednesday(),
          couponId: this.couponId(),
          rebuyCouponId: this.rebuyCouponId(),
          extraCouponId: this.extraCouponId(),
          isProductDetailsTracked: this.isProductDetailsTracked(),
          isAddToCartTracked: this.isAddToCartTracked(),
          isPurchaseCompleteTracked: this.isPurchaseCompleteTracked(),
          firstDepositInfo: this.firstDepositInfo(),
          plusSubscriptionJackpot: this.plusSubscriptionJackpot(),
          playTogetherDepositType: this.playTogetherDepositType(),
          trackedInteractionCreationAction: this.trackedInteractionCreationAction(),
        };
      }.bind(this);

      // Init:
      this.init = function () {

        // Rebuy coupon:
        if (this.rebuyCouponId() && this.state() === 'new') {
          this.copyFromOldCoupon();

          // Regular coupon:
        } else {
          if (this.state() === 'new') {
            this.addRow();
            this.addRow();

            if (this.playType() === 'Lucky') {
              this.rowsToGenerate(this.rowsToGenerate() || this.infoFeed().getLuckyDefaultRowCount());
            }
          }

          this.ready().resolve();
        }

      }.bind(this);

    });

    LottoGame.setupGame = function (options) {
      var game = undefined;

      if (options.savedCoupon) {
        return LottoGame.construct(JSON.parse(options.savedCoupon), 'new');
      }

      if (options.gameInstanceId) {
        game = LottoGame.get(options.gameInstanceId);

        if (!game || game.status() === 'completed' || (options.playType && game.playType() !== options.playType)) {
          game = undefined;
        }
      }

      if (!game) {
        game = LottoGame.construct(options, options.state || 'new');
      }

      if (options.linkToPlusPurchase && !game.linkToPlusPurchase) {
        game.linkToPlusPurchase = m.prop(options.linkToPlusPurchase);
      }

      return game;
    };

    // Public functions:
    return LottoGame;

  });
