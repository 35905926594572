defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/Core/NumbersTable',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/Core/NumbersColumn'
  ],
  function (m, NumbersColumn) {
    const NumbersTable = {
      controller: function ({ controller }) {
        Object.assign(this, controller);
        const gamesWithSpecialNumbers = ['vikinglotto', 'eurojackpot'];

        this.currentRow = this.currentRow || m.prop(1);
        this.specialNumbersType = null;
        if (gamesWithSpecialNumbers.includes(this.gameName)) {
          switch (this.gameName) {
          case 'vikinglotto':
            this.specialNumbersType = 'vikingNumber';
            break;
          case 'eurojackpot':
            this.specialNumbersType = 'starNumbers';
            break;
          }
        }
      },
      view: function (controller) {
        const isRowValid = controller.game().isRowValid(controller.currentRow(), { checkOnlyMaxLimit: true });

        return (
          <div className={`flex flex-wrap justify-center md:flex-row ${controller.useCompactView ? 'gap-8 flex-col-reverse' : 'gap-12'}`}>
            <NumbersColumn
              controller={controller}
              isRowValid={isRowValid}
            />
            {controller.specialNumbersType ? (
              <NumbersColumn
                controller={controller}
                isRowValid={isRowValid}
                specialNumbersType={controller.specialNumbersType}
              />
            ) : null}
          </div>
        );
      }
    };

    return NumbersTable;
  });