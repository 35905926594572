defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/Shared/Svg/Svg', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril'
],
function (m) {
  const Button = {
    controller: function ({ src, classNames, width, height, queryAttributes = null }) {
      const icon = src.split('#')[1];
      const fileName = src.split('.svg')[0].split('/').pop();
      const loadingStateDOM = () => {
        return (<div className='leading-0' style={`width:${width || 0}px; height:${height || 0}px;`}>
          <svg style='height: 0; width: 0; overflow: hidden; pointer-events: none; opacity: 0; z-index: -9999; top: -9999px; left: -9999px; position: absolute;'>
            <use href={src} />
          </svg>
        </div>);
      };

      this.loading = m.prop(true);
      this.renderedContent = m.prop(loadingStateDOM());

      const queryAttributesReplace = ($svg, querySelector, attributes) => {
        const selectors = querySelector.split(',');
        selectors.forEach((selector) => {
          const $elements = $svg.querySelectorAll(selector); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
          if (!$elements) {
            return;
          }
          $elements.forEach(($element) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
            for (const [key, value] of Object.entries(attributes)) {
              $element.setAttribute(key, value); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
            }
          });
        });
      };

      const observeMutations = (mutations) => {
        for (let mutation of mutations) {
          const addedNodesIds = Array.from(mutation.addedNodes).map((node) => node.id);
          if (addedNodesIds.includes(fileName)) {
            m.redraw();
          }
        }
      };
      const observer = new MutationObserver(observeMutations);

      const svgConstructor = () => {
        const $cacheContainer = document.querySelector('.svg-auto-include-container'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
        const $svgContainerFromCache = $cacheContainer?.querySelector(`#${fileName}`); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
        const $svgIconFromCache = $svgContainerFromCache?.querySelector(`#${icon}`); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element

        if (!$svgIconFromCache) {
          observer.observe($cacheContainer, { childList: true, subtree: true });
        } else {
          observer.disconnect();
        }
        if (!$cacheContainer || !$svgContainerFromCache || !$svgIconFromCache) {
          return '';
        }
        if (queryAttributes) {
          for (const [querySelector, attributes] of Object.entries(queryAttributes)) {
            queryAttributesReplace($svgIconFromCache, querySelector, attributes);
          }
        }
        const viewBoxValues = $svgIconFromCache.getAttribute('viewBox')?.split(' '); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
        const _width = width ? `${width}px` : (viewBoxValues ? `${viewBoxValues[2]}px` : '100%');
        const _height = height ? `${height}px` : (viewBoxValues ? `${viewBoxValues[3]}px` : '100%');
        const svgDOM = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        svgDOM.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
        svgDOM.setAttribute('fill', 'none');
        svgDOM.setAttribute('viewBox', $svgIconFromCache.getAttribute('viewBox')); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
        svgDOM.setAttribute('class', classNames || '');
        svgDOM.setAttribute('width', _width);
        svgDOM.setAttribute('height', _height);
        svgDOM.innerHTML = $svgIconFromCache.innerHTML;
        this.loading(false);
        this.renderedContent(m.trust(svgDOM.outerHTML));
      };

      this.onRedraw = () => {
        if (!this.loading()) {
          return;
        }
        svgConstructor();
      };

      svgConstructor();
    },
    view: function (controller) {
      controller.onRedraw();

      return (<div className='leading-0'>{
        controller.renderedContent()
      }</div>);
    }
  };

  return Button;
});