defineDs('DanskeSpil/Domain/Lotto/Scripts/Components/GameClients/LottoLuckyClient',
  [
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Params',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/Lucky/Lucky',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/ErrorOverlay',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/JokerOverlay',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/Overlay',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/StatisticsComponentForGameClient',
    'DanskeSpil/Domain/Lotto/Scripts/Models/LottoGame',
    'DanskeSpil/Domain/Lotto/Scripts/Helpers/LottoDictionary',
    'DanskeSpil/Domain/Lotto/Scripts/Helpers/LottoInfo',
    'DanskeSpil/Domain/Lotto/Scripts/Templates/LottoLucky/OptionSelector',
    'DanskeSpil/Domain/Lotto/Scripts/Templates/Subnavigation',
    'DanskeSpil/Domain/Lotto/Scripts/Templates/LottoLucky/Row',
    'DanskeSpil/Domain/Lotto/Scripts/Templates/PurchaseBar',
    'DanskeSpil/Domain/Lotto/Scripts/Templates/LottoLucky/InfoTab'
  ],
  function (Params, NumberGamesLucky, ErrorOverlay, JokerOverlay, Overlay, StatisticsComponentForGameClient, LottoGame, LottoDictionary, LottoInfo, OptionSelector, Subnavigation, Row, PurchaseBar, InfoTab) {

    // Client:
    var GameClient = function (m, settings, property) {

      // Components:
      var root = {
        controller: function () {

          this.settings = settings;
          this.property = property;

          // Variables:
          this.gameInstanceId = m.prop(Params.extract('gameInstanceId'));
          this.game = m.prop(LottoGame.setupGame({ gameInstanceId: this.gameInstanceId(), savedCoupon: settings.savedCoupon, playType: 'Lucky' }));
          this.infoFeed = m.prop(LottoInfo.saturday());
          this.chosenTab = m.prop('game');
          this.d = LottoDictionary.get;
          this.property = property;
          this.property('currentRow', 1);
          this.rowsOptions = m.prop(settings.luckyRowOptions || this.infoFeed().getLuckyRowOptions());
          this.subnavigation = m.prop(settings.quickLinks);
          this.jokerOverlay = m.prop(new Overlay(JokerOverlay(this.game(), 'lotto-lucky-game-overlay', this.d, LottoInfo.data())));
          this.errorOverlay = m.prop(new Overlay(ErrorOverlay('lotto-lucky-game-overlay', { header: this.d('GenerateRowsErrorOverlay/Header'), body: this.d('GenerateRowsErrorOverlay/Body'), dismiss: this.d('GenerateRowsErrorOverlay/Dismiss') })));

          // Functions:
          this.changeTab = function (tab) {
            var self = this;

            return function () {
              self.chosenTab(tab);
            };
          }.bind(this);

          this.purchase = function () {
            var game = this.game();
            var self = this;

            if (game.isGameValid()) {
              this.jokerOverlay().show(function () {
                game.prepareConfirm().then(function (confirmUrl) {
                  if (game.numberOfDraws() === 0) {
                    game.sendToSubscription();
                  } else {
                    location.href = confirmUrl;
                  }
                }, function () {
                  self.errorOverlay().show();
                });
              });
            }
          }.bind(this);

          // Replace state:
          if (!this.gameInstanceId() != this.game().id()) {
            Params.set('gameInstanceId=' + this.game().id());
          }

          // Tracking - Push productDetails event to dataLayer
          this.game().trackingProductDetails();

        },

        view: function (controller) {
          if (settings.useNewNumbersPicker) {
            return (<NumberGamesLucky
              controller={controller}
              gameModel={LottoGame}
              gameName={'lotto'}
              rowsOptions={LottoInfo.saturday().getLightningOptions()}
              defaultRowCount={LottoInfo.saturday().getLightningDefaultRowCount()}
            />);
          }
          return m('div', [
            m('div', { class: 'lotto-lucky-game brand-' + controller.game().gameType() }, [
              !settings.subscriptionsMode ? Subnavigation(controller) : null,
              OptionSelector(controller),
              StatisticsComponentForGameClient(controller, 'Lotto'),
              m('div', { class: 'lotto-section lotto-lucky-game-section purchase-bar-container' }, [
                m('div', { class: 'lotto-content-wrapper' }, [
                  m('div', { class: 'lotto-game-tabs-wrapper' }, [
                    m('div', { class: 'tabs-navigation' }, [
                      m('div', {
                        class: 'tab-navigation-item' + (controller.chosenTab() === 'game' ? ' active' : ''),
                        onclick: controller.changeTab('game')
                      }, [
                        m('svg', { class: 'tab-navigation-icon' }, [
                          m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesTabsNavigationIcons.svg#game-menu-icon' })
                        ])
                      ]),
                      m('div', {
                        class: 'tab-navigation-item' + (controller.chosenTab() === 'info' ? ' active' : ''),
                        onclick: controller.changeTab('info')
                      }, [
                        m('svg', { class: 'tab-navigation-icon' }, [
                          m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesTabsNavigationIcons.svg#info-icon' })
                        ])
                      ])
                    ]),
                    m('div', { class: 'tabs-container' }, [
                      controller.chosenTab() === 'game' ? m('div', { class: 'tab-container' }, [
                        m('div', { class: 'game-container' }, Row(controller))
                      ]) : null,
                      controller.chosenTab() === 'info' ? m('div', { class: 'tab-container' }, [
                        InfoTab(controller)
                      ]) : null
                    ]),
                    // m('a', { class:'how-to-play-button', href: '#how-to-play' }, 'how to play')
                  ]),
                  !settings.subscriptionsMode && !controller.property('isMultiClient') ? PurchaseBar(controller) : null
                ])
              ])
            ])
          ]);
        }
      };

      return root;

    };

    // Public functions:
    return GameClient;

  });
