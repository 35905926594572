defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/System/KeysContent',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Common/Framework/EventHandling/Scripts/Event',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/System/KeysAccordion',
  ], function (m, Event, KeysAccordion) {
    const KeysContent = {
      controller: function ({ controller, targetedSystemName = null }) {
        Object.assign(this, controller);

        this.localSystemName = m.prop(null);
        this.loadingKeys = m.prop(true);
        this.systemRows = m.prop(null);
        const keysListContentLoadingState = (<div className='rounded-16 border border-solid border-gray-2 overflow-clip'>
          <div className='bg-white text-black p-16 cursor-wait text-12 font-bold leading-16 uppercase'>{
            this.labelDictionary('systemKeysTabAccordionLabelLoading', { systemName: targetedSystemName || this.game().systemName() })
          }</div>
        </div>);
        this.keysListContent = m.prop(keysListContentLoadingState);

        function chunkArray(array, chunkSize) {
          const chunks = [];
          for (let i = 0; i < array.length; i += chunkSize) {
            chunks.push(array.slice(i, i + chunkSize));
          }
          return chunks;
        }

        const getSystemKeys = async (systemName) => {
          this.systemRows(null);
          if ((targetedSystemName === null || targetedSystemName === this.game().systemName()) &&
          this.game().isGameValid()) {
            if (this.game().generatedRows().length !== parseInt(this.game().getSystemRowAmount(), 10)) {
              await this.game().gameGenerateRows().then(() => {
                const flatRows = this.game().generatedRows().map((row) => {
                  return row.numbers.map((number) => number.number);
                });
                this.systemRows(flatRows);
              });
            }
          }

          if (!this.systemRows()) {
            await this.game().api().getSystemKeys(systemName).then((response) => {
              this.systemRows(response);
            });
          }
        };

        this.init = () => {
          this.systemName = targetedSystemName || this.game().systemName();
          const maxNumberOfNumbers = this.game().numbersPerRow();
          const totalRows = this.game().getSystemRowAmount();
          const dictionaryReplacementKeys = {
            systemName: this.systemName,
            maxNumberOfNumbers,
            totalRows,
          };

          this.heading = this.labelDictionary('systemKeysTabHeading', dictionaryReplacementKeys);
          this.subheading = this.labelDictionary('systemKeysTabSubheading', dictionaryReplacementKeys);
          this.description = this.labelDictionary('systemKeysTabDescription', dictionaryReplacementKeys);

          this.keysListContent(keysListContentLoadingState);
          getSystemKeys(this.systemName).then(() => {
            const chunkSize = 50;
            this.keysListContent(chunkArray(this.systemRows(), chunkSize).map((chunk, index) => {
              const countStartsAt = index ? (index * chunkSize) : 0;
              return <KeysAccordion
                controller={this}
                rows={chunk}
                countStartsAt={countStartsAt}
              />;
            }));
            m.redraw();
          });
          this.localSystemName(this.systemName);
        };

        const onSystemNameChange = () => {
          this.keysListContent(keysListContentLoadingState);
          this.init();
          m.redraw();
        };

        if (!targetedSystemName) {
          Event.subscribe('ds.event.numbersPicker:onSystemNameChange', onSystemNameChange);
        }

        this.onunload = () => {
          if (this.activeTab() !== 'keys') {
            Event.unsubscribe('ds.event.numbersPicker:onSystemNameChange', onSystemNameChange);
          }
        };

        this.init();
      },
      view: function (controller) {
        return (<div data-uitest-id='keys-content' className='flex flex-col gap-24'>
          <div className='min-w-18 w-content m-auto flex flex-col gap-6'>
            <div data-uitest-id='heading' className='text-16 font-bold uppercase'>{controller.heading}</div>
            <hr className='h-1 w-full border-none bg-white/80'/>
            <div data-uitest-id='subheading' className='text-12 font-bold opacity-80 uppercase'>{controller.subheading}</div>
          </div>
          <div data-uitest-id='description' className='text-14 leading-22 whitespace-pre-line'>{controller.description}</div>
          <div data-uitest-id='accordions-list' className='w-full max-w-372 mx-auto flex flex-col gap-6 mb-24'>
            {controller.keysListContent()}
          </div>
        </div>);
      }
    };
    return KeysContent;
  });