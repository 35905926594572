defineDs('DanskeSpil/Domain/Lotto/Scripts/Components/GameClients/LottoSystemClient',
  [
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Params',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/System/System',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/ErrorOverlay',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/JokerOverlay',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/Overlay',
    'DanskeSpil/Domain/NumberGames/Scripts/Templates/PrintWrapper',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/StatisticsComponentForGameClient',
    'DanskeSpil/Domain/Lotto/Scripts/Models/LottoGame',
    'DanskeSpil/Domain/Lotto/Scripts/Helpers/LottoDictionary',
    'DanskeSpil/Domain/Lotto/Scripts/Helpers/LottoInfo',
    'DanskeSpil/Domain/Lotto/Scripts/Templates/LottoSystem/TypeSwitch',
    'DanskeSpil/Domain/Lotto/Scripts/Templates/LottoSystem/OptionSelector',
    'DanskeSpil/Domain/Lotto/Scripts/Templates/Subnavigation',
    'DanskeSpil/Domain/Lotto/Scripts/Templates/ShakeOverlay',
    'DanskeSpil/Domain/Lotto/Scripts/Templates/LottoSystem/Row',
    'DanskeSpil/Domain/Lotto/Scripts/Templates/LottoSystem/InfoTab',
    'DanskeSpil/Domain/Lotto/Scripts/Templates/LottoSystem/ProfitGuaranteeTab',
    'DanskeSpil/Domain/Lotto/Scripts/Templates/LottoSystem/KeyTab',
    'DanskeSpil/Domain/Lotto/Scripts/Templates/PurchaseBar'
  ],
  function (Params, NumberGamesSystem, ErrorOverlay, JokerOverlay, Overlay, PrintWrapper, StatisticsComponentForGameClient, LottoGame, LottoDictionary, LottoInfo, TypeSwitch, OptionSelector, Subnavigation, ShakeOverlay, Row, InfoTab, ProfitGuaranteeTab, KeyTab, PurchaseBar) {

    // Client:
    var GameClient = function (m, settings, property) {

      // Components:
      var root = {
        controller: function () {

          this.settings = settings;
          this.property = property;

          // Variables:
          this.gameInstanceId = m.prop(Params.extract('gameInstanceId'));
          this.game = m.prop(LottoGame.setupGame({ gameInstanceId: this.gameInstanceId(), savedCoupon: settings.savedCoupon, playType: 'System' }));
          this.chosenTab = m.prop('game');
          this.d = LottoDictionary.get;
          this.errorOverlay = m.prop(new Overlay(ErrorOverlay('lotto-system-game-overlay', { header: this.d('GenerateRowsErrorOverlay/Header'), body: this.d('GenerateRowsErrorOverlay/Body'), dismiss: this.d('GenerateRowsErrorOverlay/Dismiss') })));
          this.jokerOverlay = m.prop(new Overlay(JokerOverlay(this.game(), 'lotto-system-game-overlay', this.d, LottoInfo.data())));
          this.property = property;
          this.property('currentRow', 1);
          this.print = m.prop(false);
          this.subnavigation = m.prop(settings.quickLinks);

          // Functions:
          this.changeTab = function (tab) {
            var self = this;

            return function () {
              self.chosenTab(tab);
            };
          }.bind(this);

          this.purchase = function () {
            var game = this.game();
            var self = this;

            if (game.isGameValid()) {
              this.jokerOverlay().show(function () {
                game.prepareConfirm().then(function (confirmUrl) {
                  if (game.numberOfDraws() === 0) {
                    game.sendToSubscription();
                  } else {
                    location.href = confirmUrl;
                  }
                }, function () {
                  self.errorOverlay().show();
                });
              });
            }
          }.bind(this);

          // Replace state:
          if (!this.gameInstanceId() != this.game().id()) {
            Params.set('gameInstanceId=' + this.game().id());
          }

          // Tracking - Push productDetails event to dataLayer
          this.game().trackingProductDetails();

        },

        view: function (controller) {
          if (settings.useNewNumbersPicker) {
            return (<NumberGamesSystem
              controller={controller}
              gameModel={LottoGame}
              gameName={'lotto'}
              systemsTypes={['M', 'R', 'C']}
            />);
          }
          return m('div', [
            PrintWrapper(controller, 'lotto', controller.chosenTab()),
            m('div', { class: 'lotto-system-game' + ' brand-' + controller.game().gameType() }, [
              !settings.subscriptionsMode ? ShakeOverlay(controller) : null,
              !settings.subscriptionsMode ? Subnavigation(controller) : null,
              TypeSwitch(controller),
              OptionSelector(controller),
              StatisticsComponentForGameClient(controller, 'Lotto'),
              m('div', { class: 'lotto-section lotto-system-game-section' }, [
                m('div', { class: 'lotto-content-wrapper purchase-bar-container' }, [
                  m('div', { class: 'lotto-game-tabs-wrapper' }, [
                    m('div', { class: 'tabs-navigation' }, [
                      m('div', { class: 'tab-navigation-item' + (controller.chosenTab() === 'game' ? ' active' : ''), onclick: controller.changeTab('game') }, [
                        m('svg', { class: 'tab-navigation-icon' }, [
                          m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesTabsNavigationIcons.svg#game-menu-icon' })
                        ])
                      ]),
                      m('div', { class: 'tab-navigation-item' + (controller.chosenTab() === 'info' ? ' active' : ''), onclick: controller.changeTab('info') }, [
                        m('svg', { class: 'tab-navigation-icon' }, [
                          m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesTabsNavigationIcons.svg#info-icon' })
                        ])
                      ]),
                      m('div', { class: 'tab-navigation-item' + (controller.chosenTab() === 'key' ? ' active' : ''), onclick: controller.changeTab('key') }, [
                        m('svg', { class: 'tab-navigation-icon' }, [
                          m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesTabsNavigationIcons.svg#key-icon' })
                        ])
                      ]),
                      m('div', { class: 'tab-navigation-item' + (controller.chosenTab() === 'profit-guarantee' ? ' active' : ''), onclick: controller.changeTab('profit-guarantee') }, [
                        m('svg', { class: 'tab-navigation-icon' }, [
                          m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesTabsNavigationIcons.svg#stamp-icon' })
                        ])
                      ])
                    ]),
                    m('div', { class: 'tabs-container' }, [

                      // Game tab:
                      controller.chosenTab() === 'game' ? m('div', { class: 'tab-container' }, [
                        m('div', { class: 'game-container' }, [
                          Row(controller)
                        ])
                      ]) : null,

                      // Info tab:
                      controller.chosenTab() === 'info' ? m('div', { class: 'tab-container' }, [
                        InfoTab(controller)
                      ]) : null,

                      // Key tab:
                      controller.chosenTab() === 'key' ? m('div', { class: 'tab-container' }, [
                        KeyTab(controller)
                      ]) : null,

                      // profit guarantee tab:
                      controller.chosenTab() === 'profit-guarantee' ? m('div', { class: 'tab-container' }, [
                        ProfitGuaranteeTab(controller)
                      ]) : null

                    ])
                  ]),
                  !settings.subscriptionsMode && !controller.property('isMultiClient') ? PurchaseBar(controller) : null
                ])
              ])
            ])
          ]);
        }
      };

      return root;

    };

    // Public functions:
    return GameClient;

  });
