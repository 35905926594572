defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/System/GuaranteeContent/MathematicalAccordions',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/Shared/Accordions/Accordion',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/System/GuaranteeContent/EurojackpotTabs',
  // eslint-disable-next-line no-unused-vars
  ], function (m, Accordion, EurojackpotTabs) {
    const MathematicalAccordions = {
      controller: function ({ controller, data }) {
        Object.assign(this, controller);
        const prizesArray = ['first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth'];
        this.accordionsData = [];

        switch (this.gameName) {
        case 'vikinglotto':
          this.oddTableRowColor = 'odd:bg-vikinglotto-blue-1/10';
          break;
        case 'eurojackpot':
          this.oddTableRowColor = 'odd:bg-eurojackpot-gold-1/10';
          break;
        default:
          this.oddTableRowColor = 'odd:bg-lotto-red-1/10';
          break;
        }

        const genericFormatter = (winningNumber, index) => {
          const number = parseInt(winningNumber, 10);
          const additional = parseInt(data.additionalNumbers[index], 10);
          const rowStructure = (prize, prizeIndex) => {
            const prizeData = data.prizes[prize]?.[index];
            if (!prizeData) {
              return null;
            }
            return <div className={`flex justify-between py-16 px-20 text-black ${this.oddTableRowColor}`}>
              <div className='text-12 leading-18 font-semibold'>{this.labelDictionary('systemGuaranteeTabAccordionMathematicalTableRowHeading', { number: prizeIndex + 1 })}</div>
              <div className='text-12 leading-18 font-semibold'>{prizeData}</div>
            </div>;
          };
          const content = <div className='bg-white'>
            {prizesArray.map((prize, prizeIndex) => rowStructure(prize, prizeIndex))}
          </div>;

          this.accordionsData.push({
            heading: this.labelDictionary(additional ? 'systemGuaranteeTabAccordionLabelWithAdditionalNumber' : 'systemGuaranteeTabAccordionLabel', { number, additional }),
            content,
          });
        };

        const eurojackpotFormatter = (column) => {
          const [number, additional] = column.name.split('+');
          const [parsedNumber, parsedAdditional] = [parseInt(number, 10), parseInt(additional, 10)];

          this.accordionsData.push({
            heading: this.labelDictionary(parsedAdditional ? 'systemGuaranteeTabAccordionLabelWithAdditionalNumber' : 'systemGuaranteeTabAccordionLabel', { number: parsedNumber, additional: parsedAdditional }),
            content: <div className='bg-white'>
              <div className='text-black text-center pb-8 px-16'>{this.labelDictionary('systemGuaranteeTabAccordionDescription', {
                numbers: parsedNumber,
                starNumbers: parsedAdditional,
                systemName: this.systemName,
              })}</div>
              <EurojackpotTabs controller={controller} column={column} />
            </div>,
          });
        };

        const dataFormat = () => {
          if (this.gameName === 'eurojackpot') {
            return data.columns.forEach((column) => eurojackpotFormatter(column));
          }

          data.winningNumbers.forEach((winningNumber, index) => genericFormatter(winningNumber, index));
        };

        dataFormat();
      },
      view: function (controller) {
        return (<div data-uitest-id='accordions-list' className='w-full max-w-372 mx-auto flex flex-col gap-6 mb-24'>
          {controller.accordionsData.map((accordionData) => {
            return <Accordion
              heading={accordionData.heading}
              content={accordionData.content}
            />;
          })}
        </div>);
      }
    };

    return MathematicalAccordions;
  });