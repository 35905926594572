defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/System/SelectorBar', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
// eslint-disable-next-line no-unused-vars
], function (m) {
  const SelectorBar = {
    controller: function ({ controller }) {
      Object.assign(this, controller);

      this.labels = {
        M: controller.labelDictionary('systemNumbersPickerSystemSelectorMathematical'),
        R: controller.labelDictionary('systemNumbersPickerSystemSelectorReduced'),
        C: controller.labelDictionary('systemNumbersPickerSystemSelectorChance'),
      };

      switch (this.gameName) {
      case 'vikinglotto':
        this.backgroundColor = 'bg-vikinglotto-blue-1';
        this.arrowColor = 'border-t-vikinglotto-blue-1';
        break;
      default:
        this.backgroundColor = 'bg-lotto-red-1';
        this.arrowColor = 'border-t-lotto-red-1';
        break;
      }
    },
    view: function (controller) {
      return (
        <div className='w-fit m-auto pt-2 pb-4 px-2'>
          <div data-uitest-id='systems-types' className='relative flex justify-center'>
            <div className='absolute w-full h-full border border-solid border-grey-3 rounded-28 box-border pointer-events-none'></div>
            {controller.systemsTypes.map((systemType) => {
              const isSelected = controller.selectedSystemType() === systemType;
              return (<div
                data-uitest-id={`system-type-selector-option-${systemType}`}
                className='relative cursor-pointer py-6 px-12'
                onclick={() => controller.onSelectSystemType(systemType)}
              >
                {isSelected ? <div className={`absolute -inset-2 rounded-28 ${controller.backgroundColor}`}>
                  <div className={`absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-full w-0 h-0 border-solid border-b-0 border-l-8 border-l-transparent border-t-5 ${controller.arrowColor} border-r-8 border-r-transparent`} />
                </div> : null}
                <div className={`relative text-14 leading-18 font-bold uppercase ${isSelected ? 'text-white' : 'text-black'}`}>{controller.labels[systemType]}</div>
              </div>);
            })}
          </div>
        </div>);
    }
  };

  return SelectorBar;
});