defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/Lucky/Lucky', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Framework/PuljeFeed/Scripts/JackpotInfo',
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Params',
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/NumberGames/Scripts/Helpers/LabelDictionary',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/Shared/Svg/Svg',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/Shared/Buttons/Button',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/Header/Header',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersRotarySelector/RotarySelector',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/Lucky/NumbersPicker',
], function (m, JackpotInfo, Params, Utils, LabelDictionary, Svg, Button, Header, RotarySelector, NumbersPicker) {
  const LuckyGame = {
    controller: function ({ controller, gameModel, gameName }) {
      Object.assign(this, controller);

      this.labelDictionary = LabelDictionary(this.settings.labels);
      this.gameName = gameName;
      this.useCompactView = Utils.isMobile();
      this.currentRow = m.prop(1);
      this.showRowsToGenerateStep = m.prop(true);
      this.gameInstanceId = m.prop(Params.extract('gameInstanceId'));
      this.game = m.prop(gameModel.get(this.gameInstanceId()));
      this.jackpotData = JackpotInfo.getByGame(this.gameName);

      if (!this.game() || this.game().playType() !== 'Lucky') {
        this.game(gameModel.setupGame({ savedCoupon: this.settings.savedCoupon, playType: 'Lucky' }));
      } else {
        const currentRowData = this.game().getRow(this.currentRow());
        const selectedNumbersCount = currentRowData.numbers.length;
        let selectedSpecialNumbersCount = 0;
        switch (this.gameName) {
        case 'vikinglotto':
          selectedSpecialNumbersCount = currentRowData.vikingNumber ? 1 : 0;
          break;
        case 'eurojackpot':
          selectedSpecialNumbersCount = currentRowData.starNumbers.length;
          break;
        }
        this.showRowsToGenerateStep(selectedNumbersCount === 0 && selectedSpecialNumbersCount === 0);
      }

      this.checkAndSetGameId = () => {
        if (!this.gameInstanceId() || this.gameInstanceId() !== this.game().id()) {
          Params.set('gameInstanceId=' + this.game().id());
        }
      };

      this.checkAndSetGameId();

      this.property('multiClientFooterCtaSlot', ({ multiClientGoToNextStep, currentFlowStepIndex }) => {
        if (currentFlowStepIndex() !== -1 || this.game().playType() !== 'Lucky') {
          return null;
        }
        const rowsToGenerate = this.game().rowsToGenerate();
        const rowPrice = this.game().rowPrice(0);
        const price = rowsToGenerate * rowPrice;
        if (this.showRowsToGenerateStep()) {
          return (
            <div className='mb-30'>
              <Button
                data-uitest-id='go-to-next-step-button'
                variant='primary'
                onclick={() => {
                  this.game().rowsToGenerate(rowsToGenerate);
                  this.game().save();
                  this.showRowsToGenerateStep(false);
                }}
              >{this.labelDictionary('luckyNumbersPickerGoToNextButton', { rowsCount: rowsToGenerate, price: price })}</Button>
            </div>
          );
        }

        const isRowsValid = this.game().getRow(this.currentRow()).numbers.length > 0;
        return (
          <div className='mb-30'>
            <Button
              data-uitest-id='go-to-next-step-button'
              variant='primary'
              disabled={!isRowsValid}
              onclick={() => {
                multiClientGoToNextStep();
              }}
            >{this.labelDictionary('luckyNumbersPickerGoToNextButton', { rowsCount: rowsToGenerate, price: price })}</Button>
          </div>
        );
      });

      this.numberPickerMaxWidthClass = 'max-w-[58.2rem]';
      switch (this.gameName) {
      case 'eurojackpot':
        this.numberPickerMaxWidthClass = 'max-w-[78rem]';
        break;
      }
    },
    view: function (controller, { gameName, rowsOptions, defaultRowCount }) {
      const wrappingClassName = `theme-numbergames theme-${gameName}`;
      if (!controller.game().rowsToGenerate()) {
        controller.game().rowsToGenerate(defaultRowCount);
      }
      if (controller.showRowsToGenerateStep()) {
        return (<div className={wrappingClassName}>
          <Header
            heading={controller.labelDictionary('luckyRowsToGenerateHeading')}
            subheading={controller.labelDictionary('luckyRowsToGenerateSubheading', {
              poolSize: `<strong class='text-black'>${controller.jackpotData.poolSizeDecimal ? `${controller.jackpotData.poolSize}` : `min ${controller.jackpotData.minimumPoolSize}`} MIO.</strong>`,
            })}
          />
          <RotarySelector
            classNames={gameName}
            gameType={gameName}
            options={rowsOptions}
            value={controller.game().rowsToGenerate}
            onchange={() => controller.game().save()}
            valuePostfix={controller.labelDictionary(controller.game().rowsToGenerate() === 1 ? 'luckyRowsToGenerateRotaryLabelSingular' : 'luckyRowsToGenerateRotaryLabelPlural')}
          />
        </div>);
      }

      return (<div className={wrappingClassName}>
        <div
          className='cursor-pointer absolute left-16 top-40 -mt-10 md:left-54 md:top-54 md:mt-6 md:-ml-4'
          onclick={() => {
            controller.showRowsToGenerateStep(true);
          }}
        >
          <Svg
            src='/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/MultiClientCommonIcons.svg#back-icon'
            width={23}
            height={14}
          />
        </div>
        <Header
          heading={controller.labelDictionary('luckyNumbersPickerHeading')}
          subheading={controller.labelDictionary('luckyNumbersPickerSubheading')}
        />
        <div className='flex justify-center mt-12 mb-20 px-16'>
          <NumbersPicker
            controller={controller}
            className={`${controller.numberPickerMaxWidthClass} p-16 rounded-16`}
          />
        </div>
      </div>);
    }
  };

  return LuckyGame;
});