defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/Coupon',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Helpers/Utils',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Params',
    'Shared/Framework/Mithril/Scripts/Helpers/Dictionary',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils',
    'DanskeSpil/Domain/NumberGames/Scripts/Models/ViewCouponModel',
    'DanskeSpil/Domain/NumberGames/Scripts/Helpers/CouponHelpers',
    'DanskeSpil/Domain/NumberGames/Scripts/Templates/GameRows',
    'DanskeSpil/Domain/NumberGames/Scripts/Templates/PrizeSections',
    'DanskeSpil/Domain/NumberGames/Scripts/Templates/PlayTogether/ClubInfo',
    'DanskeSpil/Domain/NumberGames/Scripts/Templates/Coupon/ExtraCampaignsDraws',
    'Shared/Framework/Ensighten/Scripts/Ensighten'
  ],
  function (m, GlobalUtils, Params, Dictionary, Utils, ViewCouponModel, CouponHelpers, GameRows, PrizeSections, ClubInfo, ExtraCampaignsDraws, Ensighten) {

    // Pre-init:
    var ViewCouponDictionary = new Dictionary('/NumberGames/ViewCoupon');

    // Components:
    var Coupon = {
      controller: function (args) {
        GlobalUtils.extend(this, args.controller);

        // Variables:
        this.fundingId = m.prop(Params.extract('fundingId') || this.fundingId || null);
        this.groupPlayId = m.prop(Params.extract('groupPlay') || this.groupPlayId || null);
        this.isPlayTogetherGame = m.prop(this.groupPlayId());
        this.isPlayTogetherTempCoupon = m.prop((!this.couponId && this.isPlayTogetherGame() !== null) || (!this.couponId && this.game().couponId() == null));
        this.d = ViewCouponDictionary.get;
        this.error = m.prop(null);
        this.loadingCoupon = m.prop(true);
        this.loadingFeed = m.prop(true);
        this.model = ViewCouponModel.new({
          game: Utils.formatNaming(this.gameType, 'alternativeName', { alternativeNameFormat: 'pascalCase' }),
          couponId: this.couponId || (this.game && this.game() ? this.game().couponId() : null),
          isPlayTogetherGame: this.isPlayTogetherGame(),
          isPlayTogetherTempCoupon: this.isPlayTogetherTempCoupon(),
          fundingId: this.fundingId(),
          groupPlayId: this.groupPlayId()
        });
        this.campaignsWithDrawsCount = {};
        this.campaignFromCampaignEngine = m.prop(null);

        // Feed:
        this.model.feedReady().promise.then(function () {
          this.loadingFeed(false);

          m.redraw();
        }.bind(this));

        // Setup:
        if (this.model.couponId() || this.isPlayTogetherGame()) {
          this.model.loadCoupon().then(async () => {

            const modelData = this.model.data();
            if (modelData?.campaignNumbers?.length) {
              const campaignFromCampaignEngine = await CouponHelpers.findCampaignFromCampaignEngine(modelData?.campaignNumbers).catch(() => null);
              if (campaignFromCampaignEngine) {
                this.campaignFromCampaignEngine(campaignFromCampaignEngine);

                let campaignRowCount = 0;
                if (modelData.multiWagers?.length) {
                  modelData.multiWagers.forEach((wager) => {
                    const hasCampaign = campaignFromCampaignEngine.campaignNumbers.some((campaignNumber) => {
                      return wager.campaignNumbers?.includes(campaignNumber);
                    });
                    if (hasCampaign) campaignRowCount += 1;
                  });
                } else {
                  const hasCampaign = campaignFromCampaignEngine.campaignNumbers.some((campaignNumber) => {
                    return modelData.campaignNumbers?.includes(campaignNumber);
                  });
                  if (hasCampaign) campaignRowCount += 1;
                }

                if (campaignRowCount) {
                  this.campaignsWithDrawsCount['campaign-engine'] = campaignRowCount;
                }
              }
            }

            if (modelData?.multiWagers?.length > 0 ||
              modelData?.campaigns?.length > 0) {
              const ticketCounter = (campaigns) => {
                if (campaigns?.length > 0) {
                  campaigns.forEach((campaign) => {
                    if (!this.campaignsWithDrawsCount[campaign]) {
                      this.campaignsWithDrawsCount[campaign] = 1;
                    } else {
                      this.campaignsWithDrawsCount[campaign] += 1;
                    }
                  });
                }
              };

              if (modelData.multiWagers?.length > 0) {
                modelData.multiWagers.forEach((draw) => {
                  const drawCampaigns = draw.campaigns;
                  ticketCounter(drawCampaigns);
                });
              } else {
                ticketCounter(modelData.campaigns);
              }
            }

            this.loadingCoupon(false);

            if (this.afterLoadCoupon) this.afterLoadCoupon();

            m.redraw();
          }, (response) => {
            this.loadingCoupon(false);

            this.error(response && response.errorMessage === 'NUMBERGAMES.REQUEST_VALIDATION_ERROR' ? 'RequestValidation' : 'Generic');

            m.redraw();
          });
        } else {
          this.error('Generic');

          this.loadingCoupon(false);
          this.loadingFeed(false);
        }

        const fullCampaignName = (campaignName) => {
          return Object.keys(this.campaignsWithDrawsCount).find((campaign) => {
            return campaign.indexOf(campaignName) !== -1;
          });
        };

        this.campaignDrawsCount = (campaignName) => {
          const fullName = fullCampaignName(campaignName);
          return this.campaignsWithDrawsCount[fullName];
        };

        this.hasWagerWithCampaign = (campaignName) => {
          return this.campaignDrawsCount(campaignName) > 0;
        };
      },

      view: function (controller) {
        var couponClass = function () {
          var className = 'coupon';
          if (controller.loadingCoupon() || controller.loadingFeed()) className += ' loading';
          if (controller.error()) className += ' error';
          if (controller.gameType) className += ' game-' + Utils.formatNaming(controller.gameType, 'alternativeName', { alternativeNameFormat: 'pascalCase' });
          if (controller.model &&
              controller.model.data &&
              controller.model.data() &&
              controller.model.data().prizesAvailable) className += ' prizes-available';

          return className;
        };

        return m('div', {
          class: couponClass()
        }, [
          m('.coupon__printer'),
          m('.coupon-paper', {
            config: function ($element) {
              $element.style.maxHeight = $element.children[0].offsetHeight + 'px';
            }
          }, [
            function () {
              // Loading:
              if (controller.loadingCoupon() || controller.loadingFeed()) {
                return m('.loading-content', [
                  m('h1', controller.d('LoadingHeader')),
                  m('p', controller.d('LoadingDescription'))
                ]);
              }

              // Variables:
              var coupon = controller.model.data();
              var error = controller.error();

              // Error:
              if (error) {
                return m('div', [
                  m('h1', controller.d('Errors/' + error + 'Header')),
                  m('p', controller.d('Errors/' + error + 'Description'))
                ]);
              }

              var hasCampaign = function (wager, wantedCampaigns) {
                if (controller.campaignFromCampaignEngine()) {
                  return controller.campaignFromCampaignEngine().campaignNumbers.some((campaignNumber) => {
                    return wager.campaignNumbers?.includes(campaignNumber);
                  });
                }
                return wager.campaigns.some(function (campaign) {
                  if (!campaign) return;
                  if (typeof wantedCampaigns === 'string') {
                    return campaign.indexOf(wantedCampaigns) === 0;
                  }
                  return wantedCampaigns.some(function (wantedCampaign) {
                    return campaign.indexOf(wantedCampaign) === 0;
                  });
                });
              };

              const campaignWagerTicketIcon = ({ wager, campaign, icon }) => {
                return controller.hasWagerWithCampaign(campaign) ? m('td.cell',
                  function () {
                    if (!hasCampaign(wager, campaign)) {
                      return null;
                    }

                    return m('svg.campaign-ticket', m('use', {
                      href: icon
                    }));
                  }()
                ) : null;
              };

              const campaignFooter = ({ campaign, extraClass, icon, text }) => {
                if (!controller.hasWagerWithCampaign(campaign) &&
                  campaign !== 'campaign-engine') {
                  return null;
                }

                let couponGameRows = 0;
                if (controller.isPlayTogetherGame()) {
                  couponGameRows = coupon.groupPlay?.totalRowCount;
                } else {
                  couponGameRows = coupon.playType === 'System' ?
                    coupon.systemRows :
                    coupon.games[0].rows.length;
                }
                const campaignTicketsCount = controller.campaignDrawsCount(campaign) * couponGameRows;
                const showTicketCount = () => {
                  if (controller.campaignFromCampaignEngine()) {
                    return controller.campaignFromCampaignEngine().campaignType === 'Accumulating' && campaignTicketsCount > 0;
                  }

                  return campaignTicketsCount > 0;
                };
                let _icon = null;
                if (icon) {
                  _icon = m('svg.campaign-footer__logo',
                    m('use', { href: icon })
                  );
                }
                if (controller.campaignFromCampaignEngine()) {
                  _icon = m('img.campaign-footer__logo', { src: icon });
                }
                if (!coupon.multiWagers &&
                    controller.game().extraDraw?.()) {
                  _icon = null;
                }

                return m('.campaign-footer', {
                  class: `campaign-footer--${campaign}` + (extraClass ? ` campaign-footer--${extraClass}` : '')
                }, [
                  _icon,
                  showTicketCount() ? [
                    m('.campaign-footer__tickets-count', campaignTicketsCount),
                    m('.campaign-footer__tickets-count-label', controller.d('CampaignEngine/FooterDrawsCountLabel' + (campaignTicketsCount === 1 ? 'Single' : 'Multiple')))
                  ] : null,
                  m('p.campaign-footer__text', text)
                ]);
              };

              const campaignCouponDrawIcon = () => {
                if (controller.campaignFromCampaignEngine().campaignTag?.toLowerCase() === 'christmas') {
                  return '/Components/DanskeSpil/Domain/Lotto/Graphics/SpriteSheets/LottoChristmas.svg#ticket_red';
                }
                return '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/MultiClientCommonIcons.svg#campaign_coupon';
              };

              // View:
              return m('.coupon-paper__content', [
                // Show brand logo:
                function () {
                  if (!coupon.multiWagers &&
                      controller.game().extraDraw?.() &&
                      controller.campaignFromCampaignEngine()) {
                    return m('img.campaign-header-icon', { src: controller.campaignFromCampaignEngine().graphics.logoMono });
                  }

                  if (controller.gameType === 'AllOrNothing') {
                    return m('svg', { class: 'icon' }, [
                      m('use', { href: '/Components/DanskeSpil/Domain/AllOrNothing/Graphics/SpriteSheets/AllOrNothingIcons.svg#allornothing_logo' })
                    ]);
                  }

                  if (controller.gameType === 'LottoSaturday' || controller.gameType === 'Lotto') {
                    return m('svg', { class: 'icon' }, [
                      m('use', { href: '/Components/DanskeSpil/Domain/Lotto/Graphics/SpriteSheets/LottoCommonIcons.svg#icon-lotto_logo_vindertal_dark' })
                    ]);
                  }

                  if (controller.gameType?.match(/viking/i)) {
                    return m('svg', { class: 'icon' }, [
                      m('use', { href: '/Components/DanskeSpil/Domain/VikingLotto/Graphics/SpriteSheets/VikingLottoCommonIcons.svg#icon-viking-lotto-logo_darkgrey' })
                    ]);
                  }

                  if (controller.gameType === 'Eurojackpot') {
                    return m('svg', { class: 'icon' }, [
                      m('use', { href: '/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotCommonIcons.svg#icon-eurojackpot_logo' })
                    ]);
                  }

                  if (controller.gameType === 'Keno') {
                    return m('svg', { class: 'icon' }, [
                      m('use', { href: '/Components/DanskeSpil/Domain/Keno/Graphics/SpriteSheets/KenoCommonIcons.svg#keno-logo' })
                    ]);
                  }
                }(),

                // PlayTogether specific title
                function () {
                  if (controller.isPlayTogetherGame()) {
                    return m('div', [
                      m('.play-together-title', controller.d('PlayTogether/Title'))
                    ]);
                  }
                }(),

                // Display playtype headline if the client is known (eg 'web', 'retail' etc). When SG purchases coupons based on subscriptions the coupon is tagged with client type 'Unknown'.
                // But since SG does not properly register Classic (Vælg selv) and Lightning (Lyn) we can't display the correct playtype headline. Hence we do not show this.
                coupon?.clientType !== 'Unknown' ? m('p', { class: 'play-type' }, controller.d('PlayTypes/' + Utils.formatNaming(controller.gameType, 'alternativeName', { alternativeNameFormat: 'pascalCase' }) + '/' + coupon.playType, { systemName: coupon.systemName || '' })) : '',

                // Præmiebon:
                function () {
                  if (coupon.prizesAvailable) {
                    return m('.prizes', [
                      m('p.prizes-header', controller.d('Prizes/Headline')),

                      coupon.prizeSections.length == 0 ? m('p', controller.d('Prizes/NoWinnings')) : [

                        // Gevinst-tabeller
                        PrizeSections(controller),

                        // Total gevinst
                        m('p', { class: 'total' }, [
                          controller.d('Prizes/TotalWinnings'),
                          m('span', Utils.formatCurrency(coupon.totalWinAmount))
                        ]),

                        controller.isPlayTogetherGame() ?
                          m('.playtogether-winning-section', [
                            m('p', { class: 'total' }, [
                              controller.d('PlayTogether/Prizes/YourShareWinnings'),
                              m('span', Utils.formatCurrency(coupon.groupPlay.playerShareAmount))
                            ])
                          ])
                          : null,
                      ],

                      // Show link if coupon is not active anymore
                      controller.isPlayTogetherGame() ?
                        m('p.playtogether-winning-section', [
                          m('a.playtogether-link', {
                            href: '/spil-sammen/spilleklub/mine-klubber',
                            target: '_top',
                          }, controller.d('PlayTogether/GoToLink'))
                        ])
                        : null,
                    ]);
                  }
                }(),

                // Rebuy:
                function () {
                  var gamesWithDifferentVersions = ['VikingLotto', 'Eurojackpot'];
                  // canRebuy is false if there is a game change to the game part of the gamesWithDifferentVersions (e.g. v1/v2 where v2 has less vikinglotto numbers)
                  if (controller.model.rebuyPossible() &&
                      !controller.isPlayTogetherGame() &&
                      coupon.hasOwnProperty('canRebuy') &&
                      !coupon.canRebuy &&
                      gamesWithDifferentVersions.indexOf(controller.settings.game) > -1) {

                    var cannotRebuyCouponNotice = 'CannotRebuyCouponNotice';
                    if (controller.gameType === 'Eurojackpot') cannotRebuyCouponNotice = 'EuroJackpot/CannotRebuyCouponNotice';

                    return m('p', { class: 'rebuy rebuy--inactive' }, [
                      m('a', {
                        onclick: function (ev) {
                          ev.preventDefault();
                        },
                        href: '#'
                      }, controller.d('CannotRebuyCouponButtonText')),
                      m('span.rebuy-inactive-notice', m.trust(controller.d(cannotRebuyCouponNotice)))
                    ]);
                  } else if (controller.model.rebuyPossible() && !controller.isPlayTogetherGame()) {
                    return m('p', { class: 'rebuy' }, [
                      m('a', {
                        onclick: function () {
                          Ensighten.pushGaEvent('rePurchase', 'click', controller.d('RebuyCoupon'), false);
                        },
                        href: controller.model.rebuyUrl(), target: '_blank'
                      }, controller.d('RebuyCoupon'))
                    ]);
                  }
                }(),

                // Coupon number if the game is played more than one week - eg. 1/5 if it is the first coupon of five
                function () {
                  if (coupon.multiWagerIndex !== null) {
                    for (var i = 0; i < coupon.multiWagers.length; i++) {
                      if (coupon.multiWagers[i].selected === true) {
                        if ((coupon.couponGame === 'Keno') || (coupon.couponGame === 'AllOrNothing')) {
                          return m('h4.multi-wager-count', controller.d('Headline' + coupon.couponGame + 'DrawDays', { days: coupon.multiWagers[i].name }));
                        } else if (coupon.games && coupon.games.length && coupon.games.filter(function (g) { return g.gameType === 'EuroJackpot' && g.gameVersionNo === 2; }).length) {
                          return m('h4.multi-wager-count', controller.d('HeadlineDrawAmount', { draws: coupon.multiWagers[i].name }));
                        } else {
                          if (controller.game().extraDraw?.()) {
                            return m('h4.multi-wager-count', controller.d('HeadlineDrawAmount', { draws: coupon.multiWagers[i].name }));
                          }
                          return m('h4.multi-wager-count', controller.d('HeadlineWeekAmount', { weeks: coupon.multiWagers[i].name }));
                        }
                      }
                    }
                  }
                }(),

                // Buy date:
                function () {
                  if (coupon.transactionDate) {
                    return m('p.transaction-date', Utils.formatISO8601(coupon.transactionDate));
                  }

                  if (controller.isPlayTogetherGame()) {
                    return m('p.transaction-date', Utils.formatISO8601(coupon.fundingTime));
                  }
                }(),

                // Coupon id:
                function () {
                  if (coupon.couponId) {
                    return m('p.coupon-id', controller.d('CouponId', { couponId: coupon.couponId }));
                  }
                }(),

                // PlayTogether specific
                function () {
                  if (controller.isPlayTogetherGame()) {
                    return m('div', [
                      controller.isPlayTogetherTempCoupon() ?
                        m('div', [
                          m('h2', controller.d('PlayTogether/TemporaryCoupon')),
                          m('span', m.trust(controller.d('PlayTogether/TemporaryCouponRowsNotice')))
                        ])
                        : null,
                      ClubInfo(controller, coupon)
                    ]);
                  }
                }(),

                // System:
                function () {
                  if (coupon.playType === 'System') {

                    // Keno:
                    if (coupon.couponGame === 'Keno') {
                      return m('div.chosen-system-numbers-heading', [
                        m('div', controller.d('GameTypes/KenoSystem', { level: coupon.systemLevel })),
                        m('div', m('span', coupon.games[0].systemNumbers.numbers.length + ' ' + controller.d('ChosenNumbers'))),
                        m('div', coupon.systemRows + ' ' + controller.d(coupon.couponGame + '/Row' + (coupon.systemRows != 1 ? 's' : '')))
                      ]);

                      // All other games:
                    } else {
                      return m('div.chosen-system-numbers-heading', [
                        m('div', coupon.playType + ' ' + coupon.systemName),
                        m('div', coupon.systemRows + ' ' + controller.d(coupon.couponGame + '/Row' + (coupon.systemRows != 1 ? 's' : '')))
                      ]);
                    }

                  }
                }(),

                // Print coupon data for each game (main game + jokers):
                function () {
                  if (controller.isPlayTogetherTempCoupon()) {
                    return m('div', [
                      coupon.drawFundings.length <= 1 ?
                        m('h4.draw-week', [
                          controller.d('DrawWeek', { week: coupon.drawDates.drawWeek, year: coupon.drawDates.drawYear }),
                          m('span.draw-date', coupon.drawDates.date + '.' + coupon.drawDates.month + '.' + coupon.drawDates.year)
                        ])
                        : null,
                      m('div.multi-wager-list', [
                        m('table',
                          coupon.multiWagers.map(function (wager, index) {
                            var drawDate = Utils.formatISO8601(wager.drawDate, {
                              shortenDate: true,
                              shortenMonthNames: true,
                              includeYear: false,
                              includeTime: false
                            });

                            var name = index + 1 + '/' + coupon.multiWagers.length;
                            var rows = wager.rowCount || 0;
                            var price = wager.stakeAmount || 0;
                            return m('tr.row', [
                              m('td.cell', name),
                              m('td.cell', drawDate),
                              m('td.cell', rows + (rows > 1 ? ' rækker' : ' række')),
                              controller.campaignFromCampaignEngine() ? campaignWagerTicketIcon({
                                wager,
                                campaign: 'campaign-engine',
                                icon: campaignCouponDrawIcon()
                              }) : '',
                              m('td.cell', price + ' kr.')
                            ]);
                          }))
                      ])
                    ]);
                  }

                  return coupon.games.map(function (game, i) {
                    var drawDate = Utils.parseISO8601(game.drawDate);
                    var isMultiWagers = coupon.multiWagerIndex !== null;
                    var isMultiRows = coupon.playType === 'KenoLarge' ||
                      coupon.playType === 'KenoSmall' ||
                      coupon.playType === 'KenoMillion' ||
                      coupon.playType === 'System' ||
                      (coupon.games && coupon.games[0].rows && coupon.games[0].rows.length > 1);

                    return [

                      // Only display headline for non main games:
                      i > 0 || game.gameType != coupon.couponGame ?
                        m('p', { class: 'game-type game-type-' + game.gameType }, controller.d('GameTypes/' + game.gameType))
                        : '',

                      GameRows(controller, game),

                      function () {
                        if (['EuroJackpot', 'Lotto', 'VikingLotto'].indexOf(game.gameType) !== -1) {
                          return m('.coupon-separator', m('svg.coupon-separator__line', [
                            m('line', {
                              x1: '0%',
                              x2: '100%',
                              y1: '50%',
                              y2: '50%',
                              stroke: 'currentColor',
                              'stroke-width': '4',
                              'stroke-linecap': 'round',
                              'stroke-lineJoin': 'round',
                              'stroke-dasharray': '2.39 6.37'
                            })
                          ]));
                        }
                      }(),

                      // Keno or AllOrNothing:
                      (coupon.couponGame === 'Keno' || coupon.couponGame === 'AllOrNothing') ? [

                        // Title:
                        m('h4.keno-draw-date-title', controller.d(coupon.couponGame + '/DrawDate')),

                        // Draw date:
                        m('span.keno-draw-date', drawDate.date + '.' + drawDate.month + '.' + drawDate.year),
                        // isMultiWagers ? m('span.keno-draw-date', ' - ' + lastDrawDate.date + '.' + lastDrawDate.month + '.' + lastDrawDate.year) : null,

                        // Wager:
                        (coupon.couponGame === 'Keno') ? [
                          m('h4.keno-wager-title', controller.d(coupon.couponGame + '/Wager')),
                          m('span.keno-wager', controller.d(coupon.couponGame + '/Wager' + (isMultiRows ? 'Rows' : 'Row') + (isMultiWagers ? 'Multi' : 'Single'), {
                            draws: isMultiWagers ? coupon.multiWagers.length : 1,
                            rows: coupon.playType === 'System' ? coupon.systemRows : coupon.games[0].rows.length,
                            wager: coupon.playType === 'System' ? coupon.price / coupon.systemRows : coupon.price / coupon.games[0].rows.length
                          })
                          )
                        ] : m('h4.keno-wager-title'),

                        // All other games:
                      ] : [
                        function () {
                          // Don't show draw week for temp multiwager coupons on PlayTogether
                          if (controller.isPlayTogetherTempCoupon() && isMultiWagers) {
                            return;
                          }

                          return m('h4.draw-week', [
                            controller.d('DrawWeek', { week: game.drawWeek, year: game.drawYear }),
                            m('span.draw-date', drawDate.date + '.' + drawDate.month + '.' + drawDate.year)
                          ]);
                        }()
                      ],

                      // Only show multiple weeks after the main rows:
                      i === 0 ?
                        // Week amount draw dates
                        function () {

                          if (isMultiWagers && (game.gameType !== 'Keno' && game.gameType !== 'AllOrNothing')) {
                            const prices = [...new Set(coupon.multiWagers.map((x) => x.price))];
                            const useJokerOverride = prices.length === 2;
                            const withJokerPrice = prices.reduce((price, maxPrice) => Math.max(price, maxPrice), 0);
                            var gameTypeStyling = (game.gameType !== 'LottoSaturday' || game.gameType !== 'Lotto') ? '.small-font' : '';
                            var gameSpecifics = coupon.games.reduce(function (obj, curr) {
                              if (curr.gameType.substr(0, 5).toLowerCase() === 'joker') {
                                obj.joker = true;
                              } else {
                                obj.rows += curr.rows.length;
                              }
                              return obj;
                            }, { rows: 0 });

                            return m('div.multi-wager-list', [
                              m('table',
                                coupon.multiWagers.map(function (wager) {
                                  const jokerOverride = wager.price === withJokerPrice;
                                  var drawDate = Utils.formatISO8601(wager.drawDate, {
                                    shortenDate: true,
                                    shortenMonthNames: true,
                                    includeYear: false,
                                    includeTime: false
                                  });
                                  var rows = gameSpecifics.rows || coupon.systemRows;
                                  var rowsCell = [
                                    rows + (rows > 1 ? ' rækker' : ' række')
                                  ];

                                  const campaignTicketIcon = () => {
                                    if (controller.campaignFromCampaignEngine()) {
                                      return campaignWagerTicketIcon({
                                        wager,
                                        campaign: 'campaign-engine',
                                        icon: campaignCouponDrawIcon()
                                      });
                                    }

                                    return [
                                      // Summer campaign indicator
                                      campaignWagerTicketIcon({
                                        wager,
                                        campaign: 'Sommerlotto',
                                        icon: '/Components/DanskeSpil/Domain/Lotto/Graphics/SpriteSheets/SummerLotto.svg#orange_ticket'
                                      }),
                                      // Christmas 2022 campaign indicator
                                      campaignWagerTicketIcon({
                                        wager,
                                        campaign: 'JuleLotto2022',
                                        icon: '/Components/DanskeSpil/Domain/Lotto/Graphics/SpriteSheets/LottoChristmas.svg#ticket_red'
                                      })
                                    ];
                                  };

                                  return m('tr.row' + gameTypeStyling, [
                                    m('td.cell', wager.name),
                                    m('td.cell', drawDate),
                                    m('td.cell', rowsCell),

                                    (useJokerOverride && !jokerOverride) ? m('td.cell', m.trust('&nbsp;')) : '',
                                    (useJokerOverride && jokerOverride) || (!useJokerOverride && gameSpecifics.joker) ? m('td.cell', m('svg.joker-hat', [
                                      m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons.svg#icon-joker-hat' })
                                    ])) : '',

                                    campaignTicketIcon(),

                                    m('td.cell', wager.price + ' kr.')
                                  ]);
                                }))
                            ]);
                          }
                        }()
                        : ''
                    ];
                  });
                }(),
                m(ExtraCampaignsDraws, { controller }),
                // Totalindskud:
                m('p', { class: 'total' }, [
                  function () {
                    var totalText = controller.d('TotalPrice');
                    var price = coupon.price;

                    if ((coupon.multiWagerIndex !== null) && (coupon.couponGame !== 'AllOrNothing' && coupon.couponGame !== 'Keno')) {
                      totalText = controller.d('TotalPricePerWeek');
                    }

                    if (coupon.multiWagerIndex !== null && coupon.couponGame === 'AllOrNothing') {
                      totalText = controller.d('TotalPricePerDay');
                    }

                    if (coupon.couponGame === 'Keno') {
                      totalText = controller.d('TotalPrice');
                    }

                    if (controller.isPlayTogetherGame()) {
                      totalText = controller.d('PlayTogether/TotalPrice');
                    }

                    if (controller.isPlayTogetherGame() && coupon.multiWagers.length) {
                      totalText = controller.d('PlayTogether/TotalPricePerWeek');
                      if (coupon.couponGame === 'EuroJackpot') {
                        totalText = controller.d('PlayTogether/TotalPricePerDraw');
                      }
                    }

                    // Keno multiwagers:
                    if ((coupon.couponGame === 'Keno') && coupon.multiWagers) {
                      price = price * coupon.multiWagers.length;
                    }

                    // Eurojackpot V2 with multiwager
                    if (coupon.multiWagers &&
                        coupon.multiWagers.length &&
                        coupon.games &&
                        coupon.games.length &&
                        coupon.games.filter(function (g) {
                          return g.gameType === 'EuroJackpot' && g.gameVersionNo === 2;
                        }).length) {
                      totalText = controller.d((controller.isPlayTogetherGame() ? 'PlayTogether/' : '') + 'TotalPricePerDraw');
                    }

                    if (!controller.isPlayTogetherGame() &&
                        controller.game().extraDraw?.()) {
                      totalText = controller.d('TotalPriceWithExtraDraw');
                      price = coupon.price;
                    }

                    return [
                      totalText,
                      m('span', Utils.formatCurrency(price))
                    ];
                  }()
                ]),

                // Pligttekst
                m('p.company-name', controller.d('CompanyNameDisclaimer')),

                function () {
                  if (controller.campaignFromCampaignEngine()) {
                    return campaignFooter({
                      campaign: 'campaign-engine',
                      extraClass: controller.campaignFromCampaignEngine().campaignTag ? `campaign-${controller.campaignFromCampaignEngine().campaignTag.toLowerCase()}` : '',
                      icon: controller.campaignFromCampaignEngine().graphics.logoMono,
                      text: controller.campaignFromCampaignEngine().texts.couponCampaignDetails,
                    });
                  }
                  return [
                    // Summer campaign footer
                    campaignFooter({
                      campaign: 'Sommerlotto',
                      icon: '/Components/DanskeSpil/Domain/Lotto/Graphics/SpriteSheets/SummerLotto.svg#SommerLotto_Kupon',
                      text: controller.d('SummerLotto/CouponFooter')
                    })
                  ];
                }()
              ]);
            }()
          ])
        ]);

      }
    };

    return Coupon;

  });
