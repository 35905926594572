defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/Classic/Classic',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Framework/PuljeFeed/Scripts/JackpotInfo',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Params',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils',
    'DanskeSpil/Domain/NumberGames/Scripts/Helpers/LabelDictionary',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/Shared/Svg/Svg',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/Shared/Buttons/Button',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/Header/Header',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersRotarySelector/RotarySelector',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/Classic/RowsList',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/Classic/NumbersPicker'
  ],
  function (m, JackpotInfo, Params, Utils, LabelDictionary, Svg, Button, Header, RotarySelector, RowsList, NumbersPicker) {
    const ClassicGame = {
      controller: function ({ controller, gameModel, gameName }) {
        Object.assign(this, controller);

        this.labelDictionary = LabelDictionary(this.settings.labels);
        this.useCompactView = Utils.isMobile();
        this.gameName = gameName;
        this.currentRow = m.prop(this.useCompactView ? null : 1);
        this.showRowsToGenerateStep = m.prop(true);
        this.gameInstanceId = m.prop(Params.extract('gameInstanceId'));
        this.game = m.prop(gameModel.get(this.gameInstanceId()));
        this.jackpotData = JackpotInfo.getByGame(this.gameName);

        if (!this.game() || this.game().playType() !== 'Classic') {
          this.game(gameModel.setupGame({ savedCoupon: this.settings.savedCoupon, playType: 'Classic' }));
        } else {
          const hasNumbersSelected = this.game().rows().some((row) => {
            const selectedNumbersCount = row.numbers.length;
            let selectedSpecialNumbersCount = 0;
            switch (this.gameName) {
            case 'vikinglotto':
              selectedSpecialNumbersCount = row.vikingNumber ? 1 : 0;
              break;
            case 'eurojackpot':
              selectedSpecialNumbersCount = row.starNumbers.length;
              break;
            }

            return selectedNumbersCount > 0 || selectedSpecialNumbersCount > 0;
          });
          this.showRowsToGenerateStep(!hasNumbersSelected);
        }

        this.checkAndSetGameId = () => {
          if (!this.gameInstanceId() || this.gameInstanceId() !== this.game().id()) {
            Params.set('gameInstanceId=' + this.game().id());
          }
        };

        this.checkAndSetGameId();

        this.generateNewGameModel = () => {
          this.game(gameModel.setupGame({ playType: 'Classic' }));
          this.checkAndSetGameId();
        };

        this.saveRowsToGenerate = () => {
          if (this.game().rowCount() !== this.game().rowsToGenerate()) {
            if (this.game().rowCount() > this.game().rowsToGenerate()) {
              const rowsToGenerate = this.game().rowsToGenerate();
              this.generateNewGameModel();
              this.game().rows([]);
              this.game().rowsToGenerate(rowsToGenerate);
              this.game().save();
            }
            for (let i = this.game().rowCount(); i < this.game().rowsToGenerate(); i++) {
              this.game().addRow();
            }
            this.game().save();
          }
          this.showRowsToGenerateStep(false);
        };

        this.property('multiClientFooterCtaSlot', ({ multiClientGoToNextStep, currentFlowStepIndex }) => {
          if (currentFlowStepIndex() !== -1 || this.game().playType() !== 'Classic') {
            return null;
          }
          const rowsToGenerate = this.game().rowsToGenerate();
          const rowPrice = this.game().rowPrice(0);
          let price = this.game().rowCount() * rowPrice;
          if (this.showRowsToGenerateStep()) {
            price = rowsToGenerate * rowPrice;
            return (
              <div className='mb-30'>
                <Button
                  data-uitest-id='go-to-next-step-button'
                  variant='primary'
                  onclick={() => {
                    this.saveRowsToGenerate();
                  }}
                >{this.labelDictionary('classicRowsToGenerateGoToNumberPickerButton', { rowsCount: rowsToGenerate, price: price })}</Button>
              </div>
            );
          }

          return (
            <div className='mb-30'>
              {this.game().areAllRowsValid() ? <Button
                data-uitest-id='go-to-next-step-button'
                variant='primary'
                onclick={() => {
                  multiClientGoToNextStep();
                }}
              >{this.labelDictionary('classicNumbersPickerGoToNextButton', { rowsCount: this.game().rowCount(), price: price })}</Button> :
                <Button
                  data-uitest-id='autofill-all-rows-button'
                  variant='secondary'
                  onclick={() => this.game().autofillClassicRows().then(() => {
                    m.redraw();
                  })}
                >{this.labelDictionary('classicNumbersPickerAutofillAllRows', { price: price })}</Button>
              }
            </div>
          );
        });

        this.numberPickerMaxWidthClass = 'max-w-[58.2rem]';
        switch (this.gameName) {
        case 'eurojackpot':
          this.numberPickerMaxWidthClass = 'max-w-[78rem]';
          break;
        }
      },
      view: function (controller, { gameName, rowsOptions, defaultRowCount }) {
        const wrappingClassName = `theme-numbergames theme-${gameName}`;
        if (!controller.game().rowsToGenerate()) {
          controller.game().rowsToGenerate(defaultRowCount);
        }
        if (controller.game().rows().length === 0) {
          controller.game().addRow();
        }

        if (controller.showRowsToGenerateStep()) {
          return (<div className={wrappingClassName}>
            <Header
              heading={controller.labelDictionary('classicRowsToGenerateHeading')}
              subheading={controller.labelDictionary('classicRowsToGenerateSubheading', {
                poolSize: `<strong class='text-black'>${controller.jackpotData.poolSizeDecimal ? `${controller.jackpotData.poolSize}` : `min ${controller.jackpotData.minimumPoolSize}`} MIO.</strong>`,
              })}
            />
            <RotarySelector
              classNames={gameName}
              gameType={gameName}
              options={rowsOptions}
              value={controller.game().rowsToGenerate}
              onchange={() => controller.game().save()}
              valuePostfix={controller.labelDictionary(controller.game().rowsToGenerate() === 1 ? 'classicRowsToGenerateRotaryLabelSingular' : 'classicRowsToGenerateRotaryLabelPlural')}
            />
          </div>);
        }

        return (
          <div className={wrappingClassName}>
            <div
              className='cursor-pointer absolute left-16 top-40 -mt-10 md:left-54 md:top-54 md:mt-6 md:-ml-4'
              onclick={() => {
                controller.showRowsToGenerateStep(true);
              }}
            >
              <Svg
                src='/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/MultiClientCommonIcons.svg#back-icon'
                width='23'
                height='14'
              />
            </div>
            <Header
              heading={controller.labelDictionary('classicNumbersPickerHeading')}
              subheading={controller.labelDictionary('classicNumbersPickerSubheading')}
            />
            <div className='flex justify-center gap-16 mt-12 mb-20'>
              <div className={`w-full md:max-w-372 relative ${controller.useCompactView ? '' : 'flex flex-col'}`}>
                <div className='basis-0 grow overflow-y-auto'>
                  <RowsList controller={controller} />
                  {controller.game().rowCount() < controller.game().classicMaxRows() ? <div
                    className={`w-full bg-white/60 backdrop-blur-sm flex justify-center py-16 px-20 box-border ${controller.useCompactView ? '' : ' sticky bottom-0'}`}
                  >
                    <div
                      data-uitest-id='add-new-row-button'
                      className='flex items-center gap-8 cursor-pointer'
                      onclick={() => {
                        controller.game().addRow();
                        controller.currentRow(controller.game().rows().length);
                        controller.game().save();
                      }}
                    >
                      <div className='w-32 h-32 rounded-full bg-yellow-1 flex justify-center items-center'>
                        <Svg
                          src='/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/SharedIcons.svg#plus-icon'
                          width='14'
                          height='14'
                        />
                      </div>
                      <div className='text-12 font-bold uppercase underline'>{controller.labelDictionary('classicNumbersPickerRowsListAddRow', { rowPrice: controller.game().rowPrice(0) })}</div>
                    </div>
                  </div> : null}
                </div>
              </div>
              {controller.useCompactView ? null : <div className='mt-8'>
                <NumbersPicker
                  controller={controller}
                  className={`${controller.numberPickerMaxWidthClass} p-16 rounded-16`}
                />
              </div>}
            </div>
          </div>
        );
      }
    };

    return ClassicGame;
  });