defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/Core/NumbersColumn',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/Core/NumberCell'
  ],
  // eslint-disable-next-line no-unused-vars
  function (m, NumberCell) {
    const NumbersColumn = {
      controller: function ({ controller, specialNumbersType = null }) {
        Object.assign(this, controller);

        this.cellsCount = this.game().numbersIntervalMax();
        this.maxNumbersPerRow = this.game().numbersPerRowMax;

        switch (specialNumbersType) {
        case 'starNumbers' :
          this.maxNumbersPerRow = this.game().starNumbersPerRowMax;
          this.cellsCount = this.game().starNumbersIntervalMax();
          break;
        case 'vikingNumber' :
          this.maxNumbersPerRow = this.game().vikingNumbersPerRowMax;
          this.cellsCount =  this.game().vikingNumbersIntervalMax();
          break;
        }

        this.cellsArray = Array.from({ length: this.cellsCount }, (ignoreParam, i) => {
          return { value: i + 1 };
        });

        this.wrapperClassName = 'flex flex-wrap justify-center py-8';
        if (this.useCompactView) {
          this.wrapperClassName += ' gap-8';
        } else {
          this.wrapperClassName += ' gap-12';

          if (specialNumbersType) {
            if (specialNumbersType === 'starNumbers') {
              this.wrapperClassName += ' w-120';
            }
            if (specialNumbersType === 'vikingNumber') {
              this.wrapperClassName += ' w-54';
            }
          } else {
            this.wrapperClassName += ' flex-1';
          }
        }
      },
      view: function (controller, { isRowValid = false, specialNumbersType = null }) {
        const row = controller.game().getRow(controller.currentRow());
        const isColumnValid = () => {
          if (specialNumbersType) {
            switch (specialNumbersType) {
            case 'starNumbers' :
              return row.starNumbers.length >= controller.maxNumbersPerRow();
            case 'vikingNumber' :
              return row.vikingNumber;
            }
          }

          return row.numbers.length >= controller.maxNumbersPerRow();
        };

        return (
          <div
            data-uitest-id={`${specialNumbersType ? 'special-' : ''}numbers-column`}
            className={controller.wrapperClassName}
          >
            { controller.cellsArray.map((item) => (
              <NumberCell
                controller={controller}
                value={item.value}
                isRowValid={isRowValid || isColumnValid()}
                specialNumbersType={specialNumbersType}
              />
            ))}
          </div>
        );
      }
    };

    return NumbersColumn;
  });