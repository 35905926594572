defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/System/System', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'Common/Framework/EventHandling/Scripts/Event',
  'DanskeSpil/Framework/PuljeFeed/Scripts/JackpotInfo',
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Params',
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/NumberGames/Scripts/Helpers/LabelDictionary',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/Shared/Svg/Svg',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/Shared/Buttons/Button',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/Header/Header',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/System/SelectorScreen',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/System/SelectorBar',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/System/TabsWrapper/TabsWrapper',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/System/OptionsList',
], function (m, Event, JackpotInfo, Params, Utils, LabelDictionary, Svg, Button, Header, SelectorScreen, SelectorBar, TabsWrapper, OptionsList) {
  const SystemGame = {
    controller: function ({ controller, gameModel, gameName, systemsTypes = [] }) {
      Object.assign(this, controller);

      this.labelDictionary = LabelDictionary(this.settings.labels);
      this.useCompactView = Utils.isMobile();
      this.systemsTypes = systemsTypes;
      this.selectedSystemType = m.prop(null);
      this.gameName = gameName;
      this.currentRow = m.prop(1);
      this.showSystemSelectorScreen = m.prop(true);
      this.gameInstanceId = m.prop(Params.extract('gameInstanceId'));
      this.game = m.prop(gameModel.get(this.gameInstanceId()));
      this.jackpotData = JackpotInfo.getByGame(this.gameName);

      if (!this.game() || this.game().playType() !== 'System') {
        this.game(gameModel.setupGame({ savedCoupon: this.settings.savedCoupon, playType: 'System' }));
      } else {
        const currentRowData = this.game().getRow(this.currentRow());
        const selectedNumbersCount = currentRowData.numbers.length;
        let selectedSpecialNumbersCount = 0;
        switch (this.gameName) {
        case 'vikinglotto':
          selectedSpecialNumbersCount = currentRowData.vikingNumber ? 1 : 0;
          break;
        case 'eurojackpot':
          selectedSpecialNumbersCount = currentRowData.starNumbers.length;
          break;
        }
        this.showSystemSelectorScreen(selectedNumbersCount === 0 && selectedSpecialNumbersCount === 0);
      }

      this.checkAndSetGameId = () => {
        if (!this.gameInstanceId() || this.gameInstanceId() !== this.game().id()) {
          Params.set('gameInstanceId=' + this.game().id());
        }
      };

      this.checkAndSetGameId();

      this.selectedSystemType(this.game().systemName().slice(0, 1));

      this.onSelectSystemName = (systemName) => {
        this.game().systemName(systemName);
        this.game().resetRow(1);
        Event.fire('ds.event.numbersPicker:onSystemNameChange', systemName);
      };

      this.onSelectSystemType = (systemType) => {
        this.selectedSystemType(systemType);
        const defaultSystemName = this.game().infoFeed().getSystemNames(systemType)[0];
        this.onSelectSystemName(defaultSystemName);
        this.showSystemSelectorScreen(false);
      };

      this.property('multiClientFooterCtaSlot', ({ multiClientGoToNextStep, currentFlowStepIndex }) => {
        if (currentFlowStepIndex() !== -1 || this.game().playType() !== 'System') {
          return null;
        }
        const rowsToGenerate = parseInt(this.game().getSystemRowAmount(), 10);
        const rowPrice = this.game().rowPrice(0);
        const price = rowsToGenerate * rowPrice;
        if (this.showSystemSelectorScreen() && !this.useCompactView && this.systemsTypes.length > 1) {
          return (
            <div />
          );
        }

        const isRowsValid = this.game().isRowValid(this.currentRow());
        return (
          <div className='mb-30'>
            {isRowsValid ? <Button
              data-uitest-id='go-to-next-step-button'
              variant='primary'
              onclick={() => {
                multiClientGoToNextStep();
              }}
            >{this.labelDictionary('systemNumbersPickerRowGoToNextButton', { rowsCount: rowsToGenerate, price: price })}</Button> : <Button
              data-uitest-id='autofill-all-rows-button'
              variant='secondary'
              onclick={() => {
                this.game().autogenerateNumbers(this.currentRow()).then(() => {
                  this.game().save();
                  m.redraw();
                });
              }}
            >{this.labelDictionary('systemNumbersPickerRowAutofillAllRows', { rowsCount: rowsToGenerate, price: price })}</Button>}
          </div>
        );
      });

      this.numberPickerMaxWidthClass = 'max-w-[58.2rem]';
      switch (this.gameName) {
      case 'eurojackpot':
        this.numberPickerMaxWidthClass = 'max-w-[75rem]';
        break;
      }
    },
    view: function (controller, { gameName }) {
      const wrappingClassName = `theme-numbergames theme-${gameName}`;
      if (controller.showSystemSelectorScreen() && !controller.useCompactView && controller.systemsTypes.length > 1) {
        return (<div className={wrappingClassName}>
          <Header
            heading={controller.labelDictionary('systemSelectorScreenHeading')}
            subheading={controller.labelDictionary('systemSelectorScreenSubheading', {
              poolSize: `<strong class='text-black'>${controller.jackpotData.poolSizeDecimal ? `${controller.jackpotData.poolSize}` : `min ${controller.jackpotData.minimumPoolSize}`} MIO.</strong>`,
            })}
          />
          <SelectorScreen
            controller={controller}
          />
        </div>);
      }

      return (<div className={wrappingClassName}>
        {(!controller.useCompactView && controller.systemsTypes.length > 1) ? <div
          className='cursor-pointer absolute left-16 top-40 -mt-10 md:left-54 md:top-54 md:mt-6 md:-ml-4'
          onclick={() => {
            controller.showSystemSelectorScreen(true);
          }}
        >
          <Svg
            src='/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/MultiClientCommonIcons.svg#back-icon'
            width='23'
            height='14'
          />
        </div> : null}
        <Header
          heading={controller.labelDictionary('systemNumbersPickerHeading')}
          subheading={controller.labelDictionary('systemNumbersPickerSubheading')}
        />
        {controller.systemsTypes.length > 1 ? <SelectorBar controller={controller} /> : null}
        <div className='flex flex-col justify-center gap-16 mt-12 mb-20 md:flex-row'>
          <div className={'w-full relative flex flex-col overflow-x-auto md:max-w-372'}>
            <div className='basis-0 grow md:overflow-y-auto'>
              <OptionsList controller={controller} />
            </div>
          </div>
          <TabsWrapper controller={controller} />
        </div>
      </div>);
    }
  };

  return SystemGame;
});