defineDs('DanskeSpil/Domain/Lotto/Scripts/Campaigns/Templates/Christmas/GameMode',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Framework/PuljeFeed/Scripts/JackpotInfo',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils/DateUtils',
    'Shared/Framework/Ensighten/Scripts/Ensighten',
    'DanskeSpil/Domain/Lotto/Scripts/Campaigns/Templates/Christmas/SnowLayer'
  ],
  function (m, JackpotInfo, DateUtils, Ensighten, SnowLayer) {
    const isActive = m.prop(null);

    const GameMode = {
      controller: function ({ controller }) {
        Object.assign(this, controller);

        this.dictionary = this.d.bind({ prefix: 'Campaigns/ChristmasMultiClient/PreGame/' });
        const openDraw = this.infoFeed().data()?.openDraw;

        this.isFullCampaignOn = this.campaignExtraDraw.id === openDraw?.id;
        this.modeOptions = ['normal', 'campaignDraw'];

        const setAdvanceBuy = () => {
          this.game().setFirstDrawNo(this.advanceBuyFirstDraw());
          this.game().setNumberOfDraws(this.advanceBuyDraws());
        };

        if (this.advanceBuyFirstDraw()) {
          setAdvanceBuy();
        }

        this.checkDrawModeAndTheme = () => {
          if (this.isFullCampaignOn ||
              this.game()?.firstDrawNo() ||
              this.game()?.upsellExtra?.() ||
              this.hasCampaignAsPartOfDraws?.()) {
            this.isThemeOn(true);
          } else {
            this.isThemeOn(false);
          }

          if (isActive()) {
            this.game()?.setFirstDrawNo(null);
          }

          this.$element?.classList.toggle('multi-client-wrapper--christmas-game-mode', (this.isThemeOn()));
        };

        const selectDrawMore = (drawMode) => {
          const setGameMode = ({ drawsCount, firstDraw }) => {
            this.advanceBuyDraws(drawsCount);
            this.advanceBuyFirstDraw(firstDraw);
            setAdvanceBuy();
            isActive(false);

            this.checkDrawModeAndTheme();
          };

          this.gameSelectorOptions.isVisible = false;

          switch (drawMode) {
          case 'campaignDraw':
            setGameMode({
              drawsCount: 1,
              firstDraw: this.campaignExtraDraw.id
            });
            this.goToRoute.bind(null, 'lightning')();
            Ensighten.pushGaEvent(
              'julelotto',
              'cta_click',
              'julelotto'
            );
            break;
          default:
            setGameMode({
              drawsCount: 1,
              firstDraw: null
            });
            Ensighten.pushGaEvent(
              'julelotto',
              'cta_click',
              'lotto'
            );
            this.gameSelectorOptions.isVisible = true;
          }

          this.scrollWrapperToTop();
        };

        this.onSelect = (drawMode) => {
          return () => selectDrawMore(drawMode);
        };


        if (!document.querySelector('.snow-wrapper')) {
          const snowWrapper = document.createElement('div');
          snowWrapper.classList.add('snow-wrapper');
          const component = m({
            view: () => {
              return SnowLayer(!this.isThemeOn());
            }
          });
          this.element.appendChild(snowWrapper);
          m.mount(snowWrapper, component);
        }

        const jackpotData = JackpotInfo.getByGame('lotto');
        this.jackpotText = jackpotData.poolSizeFormatted ? jackpotData.poolSizeFormatted : `min. ${jackpotData.minimumPoolSize}`;
        this.jokerJackpotText = `Jokerpulje min. ${JackpotInfo.getByGame('joker').minimumPoolSize} mio`;
        this.openDrawDay = DateUtils.formatDateWeekDay(openDraw?.drawDate);
      },
      view: function (controller) {
        controller.checkDrawModeAndTheme();
        const selectorItem = ({ mode, icon, title, subtitle, description, buttonText }) => {
          return m('.game-mode__selector-item', {
            class: mode,
            onclick: controller.onSelect(mode)
          }, m('.selector-item-inner', [
            m('img.selector-item__icon', { src: icon }),
            mode !== 'campaignDraw' ? m('.selector-item__joker', [
              m('.selector-item__joker-text', controller.jokerJackpotText),
              m('svg.selector-item__joker-arrow', m('use', {
                href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#curved-arrow-left-down'
              })),
              m('img.selector-item__joker-hat', { src: '/Components/DanskeSpil/Domain/Lotto/Graphics/SpriteSheets/LottoCommonIcons/icon-joker_hue_for_red_bg.svg' })
            ]) : null,
            m('.selector-item__content', [
              m('.selector-item__title', title),
              description ? m('.selector-item__description', description) : null,
              subtitle ? m('.selector-item__subtitle', subtitle) : null,
            ]),
            m('.selector-item__btn', buttonText),
            (mode === 'campaignDraw' && !controller.isThemeOn()) ? SnowLayer() : null
          ]));
        };
        const showStep = (mode) => {
          return controller.modeOptions.indexOf(mode) !== -1;
        };

        if (!controller.showPreGameClientStep()) {
          return m('div');
        }
        return m('.multi-client__flow-step-pre-client.multi-client__game-mode', {
          class: isActive() ? 'multi-client__flow-step-pre-client--active' : ''
        }, [
          m('.multi-client__flow-step-header', [
            controller.isFullCampaignOn ? m('svg.multi-client__game-mode-icon',
              m('use', { href: '/Components/DanskeSpil/Domain/Lotto/Graphics/SpriteSheets/LottoChristmas.svg#lotto-christmas-2021_logo' })
            ) : null,
            m('.multi-client__flow-step-title', controller.dictionary('Title')),
            m('.multi-client__flow-step-subtitle', controller.dictionary('Subtitle'))
          ]),
          m('.game-mode__selector', [
            showStep('normal') ? selectorItem({
              mode: 'normal',
              icon: '/Components/DanskeSpil/Domain/Lotto/Graphics/SpriteSheets/LottoCommonIcons/icon-lotto_logo_white.svg',
              title: controller.dictionary('Packages/Normal/Title', { drawDay: controller.openDrawDay }),
              subtitle: controller.dictionary('Packages/Normal/Subtitle'),
              description: controller.dictionary('Packages/Normal/Description', { poolSize: controller.jackpotText }),
              buttonText: controller.dictionary('Packages/Normal/ButtonText')
            }) : null,
            showStep('weeksPackage') ? selectorItem({
              mode: 'weeksPackage',
              icon: 'Lotto/Graphics/SpriteSheets/LottoChristmas.svg#multi-week-package',
              title: controller.dictionary('Packages/WeeksPackage/Title'),
              subtitle: controller.dictionary('Packages/WeeksPackage/Subtitle', { count: 1 }),
              description: m.trust(controller.dictionary('Packages/WeeksPackage/Description', { count: 1 })),
              buttonText: controller.dictionary('Packages/WeeksPackage/ButtonText')
            }) : null,
            showStep('campaignDraw') ? selectorItem({
              mode: 'campaignDraw',
              icon: controller.activeCampaignData().graphics.logoPretty,
              title: controller.dictionary('Packages/DrawPackage/Title'),
              subtitle: controller.dictionary('Packages/DrawPackage/Subtitle'),
              description: controller.dictionary('Packages/DrawPackage/Description'),
              buttonText: controller.dictionary('Packages/DrawPackage/ButtonText')
            }) : null,
            showStep('normalWithTheme') ? selectorItem({
              mode: 'normalWithTheme',
              icon: '/Components/DanskeSpil/Domain/Lotto/Graphics/SpriteSheets/LottoCommonIcons/icon-lotto_logo_white.svg',
              title: controller.dictionary('Packages/NormalWithTheme/Title', { drawDay: controller.openDrawDay }),
              subtitle: controller.dictionary('Packages/NormalWithTheme/Subtitle'),
              description: controller.dictionary('Packages/NormalWithTheme/Description', { poolSize: controller.jackpotText }),
              buttonText: controller.dictionary('Packages/NormalWithTheme/ButtonText')
            }) : null
          ])
        ]);
      },
      active: function (state) {
        if (state !== undefined) {
          isActive(state);
        }
        return isActive();
      }
    };

    return GameMode;
  }
);
