defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/System/InfoContent',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Common/Framework/EventHandling/Scripts/Event',
  ], function (m, Event) {
    const InfoContent = {
      controller: function ({ controller, targetedSystemName }) {
        Object.assign(this, controller);

        this.localSystemName = m.prop(null);
        const descriptionsMap = {
          M: 'systemInfoTabMathematicalDescription',
          R: 'systemInfoTabReducedDescription',
          C: 'systemInfoTabChanceDescription',
        };

        this.init = () => {
          const systemName = targetedSystemName || this.game().systemName();
          const maxNumberOfNumbers = this.game().numbersPerRow();
          const totalRows = this.game().getSystemRowAmount();
          const dictionaryReplacementKeys = {
            systemName,
            maxNumberOfNumbers,
            totalRows,
          };

          this.heading = this.labelDictionary('systemInfoTabHeading', dictionaryReplacementKeys);
          this.subheading = this.labelDictionary('systemInfoTabSubheading', dictionaryReplacementKeys);
          this.description = this.labelDictionary(descriptionsMap[this.selectedSystemType()], dictionaryReplacementKeys);
          this.howToPlayHeading = this.labelDictionary('systemInfoTabHowToPlayHeading', dictionaryReplacementKeys);
          this.howToPlayList = this.labelDictionary('systemInfoTabHowToPlayList', dictionaryReplacementKeys).split('\n');

          this.localSystemName(systemName);
        };

        const onSystemNameChange = () => {
          this.init();
          m.redraw();
        };

        if (!targetedSystemName) {
          Event.subscribe('ds.event.numbersPicker:onSystemNameChange', onSystemNameChange);
        }

        this.onunload = () => {
          if (this.activeTab() !== 'info') {
            Event.unsubscribe('ds.event.numbersPicker:onSystemNameChange', onSystemNameChange);
          }
        };

        this.init();
      },
      view: function (controller) {
        return (<div data-uitest-id='info-content' className='flex flex-col gap-24'>
          <div className='flex flex-col gap-24'>
            <div className='min-w-18 w-content m-auto flex flex-col gap-6'>
              <div data-uitest-id='heading' className='text-16 font-bold uppercase'>{controller.heading}</div>
              <hr className='h-1 w-full border-none bg-white/80'/>
              <div data-uitest-id='subheading' className='text-12 font-bold opacity-80 uppercase'>{controller.subheading}</div>
            </div>
            <div data-uitest-id='description' className='text-14 leading-22 whitespace-pre-line'>{controller.description}</div>
          </div>
          <div className='flex flex-col gap-8'>
            <div className='grow-0 min-w-18 w-content m-auto flex flex-col gap-6'>
              <div className='text-16 font-bold uppercase'>{controller.howToPlayHeading}</div>
              <hr className='h-1 w-full border-none bg-white/80'/>
            </div>
            <div data-uitest-id='how-to-play-list' className='flex flex-col gap-16 text-left px-8'>
              {controller.howToPlayList.map((item, index) => {
                return <div className='flex items-center gap-16'>
                  <div className='flex shrink-0 justify-center items-center w-32 h-32 bg-white rounded-full font-bold text-black border-solid border-1 border-black/50'>{index + 1}</div>
                  <div className='text-14 font-semibold leading-22'>{item}</div>
                </div>;
              })}
            </div>
          </div>
        </div>);
      }
    };
    return InfoContent;
  });